import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.starts-with.js";
import { deepWatchHandler } from '@/util/watch-handler';
export default {
  model: {
    prop: 'value',
    event: 'update:value'
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    useSvgDefaultText: {
      type: Boolean,
      default: false
    },
    svg: {
      type: String,
      default: null
    },
    qrUrl: {
      type: String,
      default: null
    },
    giftUrl: {
      type: String,
      default: null
    },
    logo: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      required: true
    },
    customsTexts: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    shippingTargets: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    previewTargetIndex: {
      type: Number,
      default: 0
    },
    rules: {
      type: Array,
      default: null
    },
    checkPlaceholder: {
      type: Boolean,
      default: false
    },
    orderer: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      texts: this.value
    };
  },
  computed: {
    previewTarget: function previewTarget() {
      var _this$shippingTargets;
      return (_this$shippingTargets = this.shippingTargets) === null || _this$shippingTargets === void 0 ? void 0 : _this$shippingTargets[this.previewTargetIndex];
    },
    hasPlaceholder: function hasPlaceholder() {
      var text = Object.values(this.texts).join('\n');
      return this.$checkIncludePlaceHolder(text);
    },
    validation: function validation() {
      var _this$rules;
      return [].concat(_toConsumableArray(this.checkPlaceholder ? [this.validCautionTextRule] : []), _toConsumableArray((_this$rules = this.rules) !== null && _this$rules !== void 0 ? _this$rules : []));
    },
    warningRules: function warningRules() {
      return this.checkPlaceholder ? [this.validEmptyPlaceholderRule] : [];
    }
  },
  watch: {
    value: {
      handler: function handler(val) {
        this.texts = val;
      },
      deep: true
    },
    texts: {
      handler: function handler(val) {
        this.$emit('update:value', val);
      },
      deep: true
    },
    shippingTargets: {
      handler: deepWatchHandler(function () {
        this.resetValidation();
      }),
      deep: true
    }
  },
  methods: {
    getFormattedText: function getFormattedText(id) {
      var _this$previewTarget, _this$texts$id, _this$texts$id2;
      return ((_this$previewTarget = this.previewTarget) === null || _this$previewTarget === void 0 ? void 0 : _this$previewTarget.contact) != null ? this.$formatPlaceHolder(this.previewTarget.contact, (_this$texts$id = this.texts[id]) !== null && _this$texts$id !== void 0 ? _this$texts$id : '', this.orderer, this.previewTarget.shippingAddress) : (_this$texts$id2 = this.texts[id]) !== null && _this$texts$id2 !== void 0 ? _this$texts$id2 : '';
    },
    validCautionTextRule: function validCautionTextRule(text) {
      var errorText = this.$getCautionText(text);
      if (errorText != null) {
        return errorText || this.$t('error.hasCautionPlaceholder');
      }
      return true;
    },
    validEmptyPlaceholderRule: function validEmptyPlaceholderRule(text) {
      var _this = this,
        _result$;
      var isMultiple = this.shippingTargets.length > 1;
      var result = this.shippingTargets.map(function (_ref) {
        var contact = _ref.contact,
          shippingAddress = _ref.shippingAddress;
        var placeholder = _this.$getEmptyPlaceholders(contact, text, _this.orderer, shippingAddress)[0];
        if (placeholder) {
          var isOrdererError = placeholder.startsWith('orderer_');
          var isAddressError = ['postcode', 'pref_name', 'city_name', 'street1', 'street2'].includes(placeholder);
          var prefix = '';
          if (isOrdererError) {
            // 例: オーダー作成者の
            prefix += _this.$t('warning.hasEmptyPlaceHolder.prefix.orderer');
          } else if (isMultiple || !isAddressError) {
            // 例: "スマポ太郎"の
            prefix += _this.$t('warning.hasEmptyPlaceHolder.prefix.contact', [_this.$fullName(contact)]);
          }
          if (isAddressError) {
            if (!(shippingAddress !== null && shippingAddress !== void 0 && shippingAddress.label)) {
              // 配送先住所の
              prefix += _this.$t('warning.hasEmptyPlaceHolder.prefix.emptyShippingAddress');
            } else {
              // 例: 配送先住所"自宅"の
              prefix += _this.$t('warning.hasEmptyPlaceHolder.prefix.shippingAddress', [shippingAddress.label]);
            }
          }
          return prefix +
          // 例: "姓"が未設定のため {last_name} は空文字に置換されます
          _this.$t('warning.hasEmptyPlaceHolder.main', [_this.$langData().insertionPlaceholder[placeholder.replace(/^orderer_/, '')], "{{".concat(placeholder, "}}")]);
        } else {
          return null;
        }
      }).filter(function (item) {
        return item != null;
      });

      // 空文字で置換されるプレースホルダーの最初の１件を警告表示する
      return (_result$ = result[0]) !== null && _result$ !== void 0 ? _result$ : true;
    },
    onUpdateError: function onUpdateError() {
      this.$emit('update:error', !this.valid());
    },
    valid: function valid() {
      var _this$$refs$texts, _this$$refs$texts$inp, _this$$refs$svg;
      return ((_this$$refs$texts = this.$refs.texts) === null || _this$$refs$texts === void 0 ? void 0 : (_this$$refs$texts$inp = _this$$refs$texts.inputs) === null || _this$$refs$texts$inp === void 0 ? void 0 : _this$$refs$texts$inp.every(function (input) {
        return input.valid;
      })) !== false && ((_this$$refs$svg = this.$refs.svg) === null || _this$$refs$svg === void 0 ? void 0 : _this$$refs$svg.valid()) !== false;
    },
    validate: function validate() {
      var _this$$refs$texts2, _this$$refs$svg2;
      return ((_this$$refs$texts2 = this.$refs.texts) === null || _this$$refs$texts2 === void 0 ? void 0 : _this$$refs$texts2.validate()) !== false && ((_this$$refs$svg2 = this.$refs.svg) === null || _this$$refs$svg2 === void 0 ? void 0 : _this$$refs$svg2.validate()) !== false;
    },
    reset: function reset() {
      var _this$$refs$texts3, _this$$refs$svg3;
      (_this$$refs$texts3 = this.$refs.texts) === null || _this$$refs$texts3 === void 0 ? void 0 : _this$$refs$texts3.reset();
      (_this$$refs$svg3 = this.$refs.svg) === null || _this$$refs$svg3 === void 0 ? void 0 : _this$$refs$svg3.reset();
    },
    resetValidation: function resetValidation() {
      var _this$$refs$texts4, _this$$refs$svg4;
      (_this$$refs$texts4 = this.$refs.texts) === null || _this$$refs$texts4 === void 0 ? void 0 : _this$$refs$texts4.resetValidation();
      (_this$$refs$svg4 = this.$refs.svg) === null || _this$$refs$svg4 === void 0 ? void 0 : _this$$refs$svg4.resetValidation();
    }
  }
};