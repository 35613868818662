import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('SplitView', {
    ref: "splitView",
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.mdAndDown
    },
    on: {
      "resize": function resize($event) {
        _vm.previewWidth = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('GiftPagePreview', {
          ref: "gift",
          attrs: {
            "logo": _vm.logo,
            "product-image": _vm.productImage,
            "product-title": _vm.productTitle,
            "user-name": _vm.getFormattedText(_vm.form.sender),
            "message": _vm.getFormattedText(_vm.form.message),
            "cta-text": _vm.getFormattedText(_vm.form.cta_text),
            "cta-link": _vm.form.cta_link,
            "width": _vm.previewWidth,
            "focus": _vm.focus,
            "error": _vm.error
          }
        })];
      },
      proxy: true
    }, _vm.edit || !_vm.$vuetify.breakpoint.mdAndDown ? {
      key: "second",
      fn: function fn() {
        var _vm$previewTarget, _vm$previewTarget2, _vm$previewTarget3, _vm$previewTarget4, _vm$previewTarget5, _vm$previewTarget6;
        return [_vm.edit ? _c(VForm, {
          ref: "form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
            }
          }
        }, [_c('Input', {
          ref: "sender",
          attrs: {
            "label": _vm.$t('giftSender'),
            "required": _vm.required,
            "rules": _vm.validation,
            "warning-rules": _vm.warningRules
          },
          on: {
            "focus": function focus($event) {
              _vm.$nextTick(function () {
                return _vm.focus = "sender";
              });
            },
            "blur": function blur($event) {
              _vm.focus = null;
            },
            "update:error": function updateError($event) {
              _vm.error.sender = $event;
            },
            "contextmenu": function contextmenu($event) {
              $event.preventDefault();
              return _vm.$emit('contextmenu', $event);
            }
          },
          model: {
            value: _vm.form.sender,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "sender", $$v);
            },
            expression: "form.sender"
          }
        }), _vm._v(" "), _c('Input', {
          ref: "message",
          staticClass: "mt-2",
          attrs: {
            "label": _vm.$t('giftMessage'),
            "required": _vm.required,
            "type": "multiLine",
            "auto-grow": "",
            "rows": "2",
            "max-rows": "10",
            "rules": _vm.validation,
            "warning-rules": _vm.warningRules
          },
          on: {
            "focus": function focus($event) {
              _vm.$nextTick(function () {
                return _vm.focus = "message";
              });
            },
            "blur": function blur($event) {
              _vm.focus = null;
            },
            "update:error": function updateError($event) {
              _vm.error.message = $event;
            },
            "contextmenu": function contextmenu($event) {
              $event.preventDefault();
              return _vm.$emit('contextmenu', $event);
            }
          },
          model: {
            value: _vm.form.message,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "message", $$v);
            },
            expression: "form.message"
          }
        }), _vm._v(" "), _c('Input', {
          ref: "ctaText",
          staticClass: "mt-2",
          attrs: {
            "label": _vm.$t('customButton'),
            "placeholder": _vm.$t('buttonName'),
            "rules": _vm.ctaTextValidation,
            "warning-rules": _vm.warningRules,
            "data-1p-ignore": ""
          },
          on: {
            "focus": function focus($event) {
              _vm.$nextTick(function () {
                return _vm.focus = "button";
              });
            },
            "blur": function blur($event) {
              _vm.focus = null;
            },
            "update:error": _vm.updateCtaError,
            "input": _vm.updateCtaError,
            "contextmenu": function contextmenu($event) {
              $event.preventDefault();
              return _vm.$emit('contextmenu', $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "label.prepend",
            fn: function fn() {
              return [_c('FloatingTooltip', {
                attrs: {
                  "right": "",
                  "max-width": "260px"
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on;
                    return [_c(VIcon, _vm._g({
                      staticClass: "ml-1",
                      attrs: {
                        "small": ""
                      }
                    }, on), [_vm._v("mdi-information")])];
                  }
                }], null, false, 1960462187)
              }, [_vm._v(" "), _c('span', {
                staticClass: "text-body-2"
              }, [_vm._v(_vm._s(_vm.$t('hintCtaTextAndLink')))])])];
            },
            proxy: true
          }], null, false, 661202913),
          model: {
            value: _vm.form.cta_text,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "cta_text", $$v);
            },
            expression: "form.cta_text"
          }
        }), _vm._v(" "), _c('Input', {
          ref: "ctaLink",
          staticClass: "mt-2",
          attrs: {
            "placeholder": _vm.$t('destinationUrl'),
            "rules": _vm.ctaLinkValidation
          },
          on: {
            "focus": function focus($event) {
              _vm.$nextTick(function () {
                return _vm.focus = "button";
              });
            },
            "blur": function blur($event) {
              _vm.focus = null;
            },
            "update:error": _vm.updateCtaError,
            "input": _vm.updateCtaError
          },
          model: {
            value: _vm.form.cta_link,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "cta_link", $$v);
            },
            expression: "form.cta_link"
          }
        })], 1) : [_c('InsertionFrameText', {
          attrs: {
            "value": _vm.form.sender,
            "label": _vm.$t('giftSender'),
            "contact": (_vm$previewTarget = _vm.previewTarget) === null || _vm$previewTarget === void 0 ? void 0 : _vm$previewTarget.contact,
            "orderer": _vm.orderer,
            "address": (_vm$previewTarget2 = _vm.previewTarget) === null || _vm$previewTarget2 === void 0 ? void 0 : _vm$previewTarget2.shippingAddress,
            "replace": _vm.replace
          }
        }), _vm._v(" "), _c('InsertionFrameText', {
          staticClass: "mt-2",
          attrs: {
            "value": _vm.form.message,
            "label": _vm.$t('giftMessage'),
            "contact": (_vm$previewTarget3 = _vm.previewTarget) === null || _vm$previewTarget3 === void 0 ? void 0 : _vm$previewTarget3.contact,
            "orderer": _vm.orderer,
            "address": (_vm$previewTarget4 = _vm.previewTarget) === null || _vm$previewTarget4 === void 0 ? void 0 : _vm$previewTarget4.shippingAddress,
            "replace": _vm.replace
          }
        }), _vm._v(" "), _c('SmallLabel', {
          staticClass: "mt-2 pb-1",
          attrs: {
            "label": _vm.$t('customButton')
          }
        }), _vm._v(" "), _vm.form.cta_text && _vm.form.cta_link ? [_c('InsertionFrameText', {
          staticClass: "ml-2",
          attrs: {
            "value": _vm.form.cta_text,
            "label": _vm.$t('buttonName'),
            "contact": (_vm$previewTarget5 = _vm.previewTarget) === null || _vm$previewTarget5 === void 0 ? void 0 : _vm$previewTarget5.contact,
            "orderer": _vm.orderer,
            "address": (_vm$previewTarget6 = _vm.previewTarget) === null || _vm$previewTarget6 === void 0 ? void 0 : _vm$previewTarget6.shippingAddress,
            "replace": _vm.replace
          }
        }), _vm._v(" "), _c('FrameText', {
          staticClass: "mt-2 ml-2",
          attrs: {
            "value": _vm.form.cta_link,
            "label": _vm.$t('destinationUrl')
          }
        })] : _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n        " + _vm._s(_vm.$t('hidden')) + "\n      ")]), _vm._v(" "), _vm.hasPlaceholder ? _c('div', {
          staticClass: "tw-flex tw-justify-end tw-mt-4"
        }, [_c('TwSwitch', {
          attrs: {
            "value": _vm.replace,
            "text": _vm.$t('action.showReplacedPlaceholerText')
          },
          on: {
            "update:value": function updateValue($event) {
              _vm.replace = $event;
            }
          }
        })], 1) : _vm._e()]];
      },
      proxy: true
    } : null], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };