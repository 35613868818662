import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm$productCustoms$te, _vm$productCustoms$te2, _vm$data$touch, _vm$data$touch2, _vm$data$touch3, _vm$data$touch$shopif, _vm$data$touch$shopif2, _vm$data$touch$shopif3;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('action.creationCampaign')))])])], 1), _vm._v(" "), _c('ContentArea', {
    attrs: {
      "set": _vm.labelNumber = 1
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step1 ? 'primary' : null
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('campaignName')) + "\n      ")]), _vm._v(" "), _c('Input', {
    attrs: {
      "placeholder": _vm.$t('campaignName'),
      "maxlength": "255"
    },
    model: {
      value: _vm.data.campaignName,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "campaignName", $$v);
      },
      expression: "data.campaignName"
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step2 ? 'primary' : null
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('action.selectionTouch')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "p-relative"
  }, [_c('Loading', {
    attrs: {
      "value": _vm.loading.product
    }
  }), _vm._v(" "), _c('TouchItems', {
    attrs: {
      "selectable": "",
      "type": _vm.type,
      "filters": _vm.filterOptions,
      "options": _vm.tableOptions,
      "value": _vm.data.touch
    },
    on: {
      "update:type": function updateType($event) {
        _vm.type = $event;
      },
      "update:filters": function updateFilters($event) {
        _vm.filterOptions = $event;
      },
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "update:value": _vm.onClickItem
    }
  })], 1), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step3 ? 'primary' : null
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('action.selectionDestination')) + "\n      ")]), _vm._v(" "), _c(VFadeTransition, [!_vm.isDigitalGift && _vm.data.contacts.some(function (contact) {
    return !_vm.$isFillAddress(_vm.data.selectedAddresses[contact.id]);
  }) ? _c('div', {
    staticClass: "mb-4"
  }, [_c('AlertArea', {
    attrs: {
      "type": "warning",
      "no-margin": "",
      "message": _vm.$t('warning.noSelectableAddressesContact')
    }
  }), _vm._v(" "), _c('AddressAlert', {
    staticClass: "mt-2",
    attrs: {
      "address": null,
      "at-campaign": ""
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c(VFadeTransition, [_vm.notSetSalutationCount ? _c('AlertArea', {
    staticClass: "mb-4",
    attrs: {
      "type": "info",
      "no-margin": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm._v("\n            " + _vm._s(_vm.$t('info.notSetSalutationCampaign', [_vm.notSetSalutationCount])) + "\n          ")];
      },
      proxy: true
    }], null, false, 2578176413)
  }, [_vm._v(" "), _c('TextButton', {
    attrs: {
      "value": _vm.$t('action.doSetAll')
    },
    on: {
      "click": _vm.onClickAddAllSalutations
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _vm.data.contacts.length ? _c('OutlinedTable', {
    staticClass: "mb-6",
    attrs: {
      "sort-desc": ['created_at'],
      "headers": [{
        text: '',
        value: 'remove',
        ellipsis: false,
        sortable: false,
        align: 'center',
        width: 1
      }, {
        text: '氏名',
        value: 'name',
        getter: _vm.$fullName
      }, {
        text: '敬称',
        value: 'title',
        empty: _vm.$t('notSet')
      }, {
        text: '会社名',
        value: 'account.name',
        getter: function getter(val) {
          var _val$account$name, _val$account;
          return (_val$account$name = (_val$account = val.account) === null || _val$account === void 0 ? void 0 : _val$account.name) !== null && _val$account$name !== void 0 ? _val$account$name : val.crm_company_name;
        },
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: '部署',
        value: 'department',
        empty: _vm.$t('notSet')
      }, {
        text: '役職',
        value: 'job_title',
        empty: _vm.$t('notSet')
      }, {
        text: '所有者(担当者)',
        value: 'owner.name',
        empty: _vm.$t('notSet')
      }, {
        text: '最終送付日',
        value: 'last_sent',
        transform: _vm.$date,
        empty: _vm.$t('none')
      }, !_vm.isDigitalGift ? {
        text: '配送先住所',
        value: 'address',
        ellipsis: false
      } : null].filter(function (item) {
        return item != null;
      }),
      "items": _vm.data.contacts,
      "item-key": "id",
      "page": _vm.page,
      "disable-sort": "",
      "fixed-last-column": !_vm.isDigitalGift
    },
    scopedSlots: _vm._u([{
      key: "footer.prepend",
      fn: function fn() {
        return [_c('TextButton', {
          attrs: {
            "icon": "mdi-trash-can-outline",
            "value": _vm.$t('action.doClearDestination')
          },
          on: {
            "click": _vm.deleteAll
          }
        })];
      },
      proxy: true
    }, {
      key: "item.remove",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('button', {
          staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-primary -tw-ml-4",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.deleteSelected(item);
            }
          }
        }, [_c('mdi-close', {
          staticClass: "tw-size-6"
        })], 1)];
      }
    }, {
      key: "item.title",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.title ? _c('div', [_vm._v(_vm._s(item.title))]) : _c('Input', {
          staticStyle: {
            "width": "65px"
          },
          attrs: {
            "value": _vm.data.salutations[item.id],
            "placeholder": _vm.$t('notSet'),
            "dense": ""
          },
          on: {
            "input": function input($event) {
              return _vm.onChangeSalutation(item, $event);
            }
          }
        })];
      }
    }, {
      key: "item.address",
      fn: function fn(_ref3) {
        var _item$account$shippin, _item$account;
        var item = _ref3.item;
        return [(_vm.selectableAddresses = [].concat(_toConsumableArray((_item$account$shippin = (_item$account = item.account) === null || _item$account === void 0 ? void 0 : _item$account.shipping_addresses) !== null && _item$account$shippin !== void 0 ? _item$account$shippin : []), _toConsumableArray(item.shipping_addresses))) ? void 0 : _vm._e(), _vm._v(" "), _vm.selectableAddresses.length ? _c('Input', {
          attrs: {
            "value": _vm.data.selectedAddresses[item.id],
            "required": "",
            "type": "select",
            "items": _vm.selectableAddresses,
            "item-text": "label",
            "item-value": "id",
            "return-object": "",
            "placeholder": _vm.$t('placeholder.shippingAddress'),
            "dense": ""
          },
          on: {
            "input": function input($event) {
              _vm.data.selectedAddresses[item.id] = $event;
            },
            "click": function click($event) {
              $event.stopPropagation();
            }
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref4) {
              var address = _ref4.item;
              return [_c('AddressLabel', {
                attrs: {
                  "small": "",
                  "alert-color": "warning",
                  "address": address
                }
              })];
            }
          }, {
            key: "item",
            fn: function fn(_ref5) {
              var _vm$data$selectedAddr;
              var address = _ref5.item,
                attrs = _ref5.attrs;
              return [_c('AddressLabel', {
                attrs: {
                  "detail": "",
                  "address": address,
                  "active-class": attrs.activeClass,
                  "selected": ((_vm$data$selectedAddr = _vm.data.selectedAddresses[address.id]) === null || _vm$data$selectedAddr === void 0 ? void 0 : _vm$data$selectedAddr.id) === address.id
                }
              })];
            }
          }], null, true)
        }) : _c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VIcon, {
          staticClass: "mr-2",
          attrs: {
            "size": "20",
            "color": "warning"
          }
        }, [_vm._v("mdi-alert")]), _vm._v(" "), _c('span', {
          staticClass: "text-body-2 warning--text"
        }, [_vm._v(_vm._s(_vm.$t('notSet')))])], 1)];
      }
    }], null, false, 4037933222)
  }) : _vm._e(), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-dotted tw-w-full",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.dialog.contact = true;
      }
    }
  }, [_c('mdi-account-plus-outline', {
    staticClass: "tw-mr-2 tw-size-6 tw-shrink-0"
  }), _vm._v("\n        " + _vm._s(_vm.$t('action.doAddDestination')) + "\n      ")], 1), _vm._v(" "), !_vm.isDigitalGift ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step4 ? 'primary' : null
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('shipping.selectDesiredArrivalDate')) + "\n          "), _c('IconButton', {
    attrs: {
      "tooltip": _vm.$t('shipping.desiredArrivalDatePrecautions'),
      "icon": "mdi-alert"
    },
    on: {
      "click": _vm.$openDesiredArrivalDatePrecautions
    }
  })], 1), _vm._v(" "), _c('ShippingDateSelect', {
    attrs: {
      "shipping-date": _vm.data.inhouseDate,
      "shipping-method": _vm.data.shippingMethod
    },
    on: {
      "update:shippingDate": function updateShippingDate($event) {
        return _vm.$set(_vm.data, "inhouseDate", $event);
      },
      "update:shipping-date": function updateShippingDate($event) {
        return _vm.$set(_vm.data, "inhouseDate", $event);
      },
      "update:shippingMethod": function updateShippingMethod($event) {
        return _vm.$set(_vm.data, "shippingMethod", $event);
      },
      "update:shipping-method": function updateShippingMethod($event) {
        return _vm.$set(_vm.data, "shippingMethod", $event);
      }
    }
  })] : _vm._e(), _vm._v(" "), ((_vm$productCustoms$te = _vm.productCustoms.texts) === null || _vm$productCustoms$te === void 0 ? void 0 : _vm$productCustoms$te.length) > 0 || _vm.productCustoms.url ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('div', {
    staticClass: "p-relative"
  }, [_c('Loading', {
    attrs: {
      "value": _vm.loading.product
    }
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step5 ? 'primary' : null
    },
    scopedSlots: _vm._u([_vm.complementedContacts.length > 1 && _vm.hasContactPlaceholderText ? {
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "tw-flex tw-items-center tw-gap-2 tw-self-end"
        }, [_c('span', {
          staticClass: "tw-shrink-0 tw-text-subtitle-2"
        }, [_vm._v("\n                  " + _vm._s(_vm.$t('usePreviewDestination')) + " :\n                ")]), _vm._v(" "), _c('Input', {
          staticClass: "tw-flex-grow-0 tw-w-48 [&_fieldset]:tw-rounded-none [&_fieldset]:tw-border-0 [&_fieldset]:tw-border-b",
          attrs: {
            "type": "select",
            "items": _vm.complementedContacts,
            "item-text": function itemText(item) {
              return _vm.$fullName(item);
            },
            "item-value": function itemValue(item) {
              return _vm.complementedContacts.indexOf(item);
            },
            "dense": "",
            "clearable": false
          },
          model: {
            value: _vm.textPreviewTargetIndex,
            callback: function callback($$v) {
              _vm.textPreviewTargetIndex = $$v;
            },
            expression: "textPreviewTargetIndex"
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_vm._v("\n            " + _vm._s(_vm.$t('shipping.customizePrintedText')) + "\n            ")]), _vm._v(" "), ((_vm$productCustoms$te2 = _vm.productCustoms.texts) === null || _vm$productCustoms$te2 === void 0 ? void 0 : _vm$productCustoms$te2.length) > 0 ? _c('TouchTextViewer', {
    key: "svg-".concat((_vm$data$touch = _vm.data.touch) === null || _vm$data$touch === void 0 ? void 0 : _vm$data$touch.id),
    attrs: {
      "svg": _vm.data.svg,
      "shipping-targets": _vm.complementedContacts.map(function (contact) {
        return {
          contact: contact,
          shippingAddress: _vm.data.selectedAddresses[contact.id]
        };
      }),
      "previewTargetIndex": _vm.textPreviewTargetIndex,
      "qr-url": _vm.data.url,
      "logo": _vm.company.image,
      "customs-texts": _vm.productCustoms.texts,
      "edit": "",
      "required": "",
      "check-placeholder": ""
    },
    on: {
      "update:error": function updateError($event) {
        _vm.hasTextError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _vm.data.customTexts,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "customTexts", $$v);
      },
      expression: "data.customTexts"
    }
  }) : _vm._e(), _vm._v(" "), _vm.productCustoms.url ? _c('Input', {
    key: "url-".concat((_vm$data$touch2 = _vm.data.touch) === null || _vm$data$touch2 === void 0 ? void 0 : _vm$data$touch2.id),
    staticClass: "mt-6",
    attrs: {
      "label": _vm.$t('qrUrl'),
      "required": "",
      "rules": [function (v) {
        return _vm.$isValidUrl(v) || _vm.$t('error.url');
      }]
    },
    model: {
      value: _vm.data.url,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "url", $$v);
      },
      expression: "data.url"
    }
  }) : _vm._e()], 1)] : _vm._e(), _vm._v(" "), _vm.hasGiftProduct ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step6 ? 'primary' : null
    },
    scopedSlots: _vm._u([_vm.complementedContacts.length > 1 && _vm.hasContactPlaceholderGift ? {
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "tw-flex tw-items-center tw-gap-2 tw-self-end"
        }, [_c('span', {
          staticClass: "tw-shrink-0 tw-text-subtitle-2"
        }, [_vm._v("\n                " + _vm._s(_vm.$t('usePreviewDestination')) + " :\n              ")]), _vm._v(" "), _c('Input', {
          staticClass: "tw-flex-grow-0 tw-w-48 [&_fieldset]:tw-rounded-none [&_fieldset]:tw-border-0 [&_fieldset]:tw-border-b",
          attrs: {
            "type": "select",
            "items": _vm.complementedContacts,
            "item-text": function itemText(item) {
              return _vm.$fullName(item);
            },
            "item-value": function itemValue(item) {
              return _vm.complementedContacts.indexOf(item);
            },
            "dense": "",
            "clearable": false
          },
          model: {
            value: _vm.giftPreviewTargetIndex,
            callback: function callback($$v) {
              _vm.giftPreviewTargetIndex = $$v;
            },
            expression: "giftPreviewTargetIndex"
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_vm._v("\n          " + _vm._s(_vm.$t('shipping.customizeGiftPage')) + "\n          ")]), _vm._v(" "), _c('GiftPageEditor', {
    key: "gift-".concat((_vm$data$touch3 = _vm.data.touch) === null || _vm$data$touch3 === void 0 ? void 0 : _vm$data$touch3.id),
    attrs: {
      "edit": "",
      "required": "",
      "check-placeholder": "",
      "logo": _vm.company.image,
      "shipping-targets": _vm.complementedContacts.map(function (contact) {
        return {
          contact: contact,
          shippingAddress: _vm.data.selectedAddresses[contact.id]
        };
      }),
      "previewTargetIndex": _vm.giftPreviewTargetIndex,
      "product-title": (_vm$data$touch$shopif = _vm.data.touch.shopify_product.display_gift_name) !== null && _vm$data$touch$shopif !== void 0 ? _vm$data$touch$shopif : _vm.data.touch.shopify_product.variant_title,
      "product-image": (_vm$data$touch$shopif2 = _vm.data.touch.shopify_product.display_gift_image) !== null && _vm$data$touch$shopif2 !== void 0 ? _vm$data$touch$shopif2 : (_vm$data$touch$shopif3 = _vm.data.touch.shopify_product.images) === null || _vm$data$touch$shopif3 === void 0 ? void 0 : _vm$data$touch$shopif3[0]
    },
    on: {
      "update:error": function updateError($event) {
        _vm.hasGiftError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _vm.data.lp,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "lp", $$v);
      },
      expression: "data.lp"
    }
  })] : _vm._e()], 2), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSubmitEnable
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.doCheckCampaign'))
    },
    on: {
      "click": _vm.submit
    }
  })])], 1), _vm._v(" "), _c('SelectContactDialog', {
    attrs: {
      "value": _vm.data.contacts,
      "show": _vm.dialog.contact
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "contact", $event);
      },
      "change": _vm.onChangeSelectedContact
    }
  }), _vm._v(" "), _c('TextEditorContextMenu', {
    ref: "textmenu"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };