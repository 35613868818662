/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./DateInput.vue?vue&type=template&id=5e137d48&scoped=true"
import script from "./DateInput.vue?vue&type=script&lang=js"
export * from "./DateInput.vue?vue&type=script&lang=js"
import style0 from "./DateInput.vue?vue&type=style&index=0&id=5e137d48&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e137d48",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmallLabel: require('/code/src/components/text/SmallLabel.vue').default,CustomDatePicker: require('/code/src/components/input/CustomDatePicker.vue').default,FormHighlight: require('/code/src/components/FormHighlight.vue').default})
