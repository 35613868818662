var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tw-relative tw-aspect-[1/1] tw-w-full tw-max-h-[160px] tw-overflow-hidden"
  }, [_setup.loading || _vm.dummy ? _c('SkeletonLoader', {
    staticClass: "tw-absolute tw-inset-0"
  }) : _vm._e(), _vm._v(" "), !_vm.dummy ? _c('img', {
    staticClass: "tw-size-full tw-object-cover",
    attrs: {
      "src": _setup.url,
      "draggable": "false"
    },
    on: {
      "load": function load($event) {
        _setup.loading = false;
      },
      "error": function error($event) {
        _setup.url = '/error/no_image.svg';
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };