/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./SvgTextInput.vue?vue&type=template&id=4c5e05ba&scoped=true"
import script from "./SvgTextInput.vue?vue&type=script&lang=js"
export * from "./SvgTextInput.vue?vue&type=script&lang=js"
import style0 from "./SvgTextInput.vue?vue&type=style&index=0&id=4c5e05ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5e05ba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmallLabel: require('/code/src/components/text/SmallLabel.vue').default,IconButton: require('/code/src/components/button/IconButton.vue').default,DecoratableTextArea: require('/code/src/components/svg/DecoratableTextArea.vue').default})
