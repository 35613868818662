import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('PhonePreview', {
    attrs: {
      "width": _vm.width
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center justify-center color-background pa-3",
    staticStyle: {
      "min-height": "100%"
    }
  }, [_c('ContentArea', {
    class: [_vm.focus ? "focus-".concat(_vm.focus) : null],
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "476px"
    }
  }, [_c(VImg, {
    staticClass: "ma-auto",
    attrs: {
      "src": _vm.logo,
      "height": "auto",
      "width": "150px"
    }
  }), _vm._v(" "), _c('h5', {
    staticClass: "mt-4 mb-6 text-center"
  }, [_c('span', {
    staticClass: "sender",
    class: {
      'disabled-text--text': !_vm.userName,
      'focus-error': _vm.error.sender
    }
  }, [_vm._v(_vm._s(_vm.userName ? _vm.userName : _vm.$t('giftSender')))]), _vm._v(_vm._s(_vm.$t('from') + _vm.$t('wow.receivedGiftMessage')) + "\n        ")]), _vm._v(" "), _c(VCard, {
    staticClass: "mb-6",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex overflow-hidden"
  }, [_c('CoverImage', {
    staticClass: "tw-flex-shrink !tw-w-[150px] !tw-min-w-[120px]",
    attrs: {
      "src": _vm.productImage
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-1 align-self-center pa-3"
  }, [_vm._v("\n              " + _vm._s(_vm.productTitle) + "\n            ")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "rounded color-background pa-4 text-body-1 d-flex flex-column align-center mx-auto"
  }, [_c('div', {
    staticClass: "message d-flex flex-column align-center",
    class: {
      'focus-error': _vm.error.message
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_vm.message ? _c('pre', {
    staticClass: "d-inline-block",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.message))]) : _c('span', {
    staticClass: "disabled-text--text"
  }, [_vm._v("\n              " + _vm._s(_vm.$t('previewHere', [_vm.$t('giftMessage')])) + "\n            ")])]), _vm._v(" "), _vm.focus === 'button' || _vm.ctaLink || _vm.ctaText ? _c('div', {
    staticClass: "button mt-4 d-flex flex-column align-center",
    class: {
      'focus-error': _vm.error.button
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('a', {
    staticClass: "tw-btn tw-btn-outline-primary",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "href": _vm.ctaLink,
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_c('span', {
    staticClass: "tw-text-center"
  }, [_vm._v("\n                " + _vm._s(_vm.ctaText || _vm.$t('previewHere', [_vm.$t('customButton')])) + "\n              ")])])]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-fill-primary my-6",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('wow.receive'))
    }
  })])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-center mt-4"
  }, [_c('TextButton', {
    attrs: {
      "value": _vm.$t('wow.donate')
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };