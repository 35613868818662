/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./TouchItems.vue?vue&type=template&id=9872ee54"
import script from "./TouchItems.vue?vue&type=script&lang=ts&setup=true"
export * from "./TouchItems.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BetaIcon: require('/code/src/components/icons/BetaIcon.vue').default,ContextMenu: require('/code/src/components/menu/ContextMenu.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,Input: require('/code/src/components/input/Input.vue').default,DateInput: require('/code/src/components/input/DateInput.vue').default,ProductDummyCard: require('/code/src/components/widgets/ProductDummyCard.vue').default,ProductCard: require('/code/src/components/widgets/ProductCard.vue').default,IconButton: require('/code/src/components/button/IconButton.vue').default,TableTypeSelect: require('/code/src/components/table/TableTypeSelect.vue').default,CustomVDataFooter: require('/code/src/components/table/CustomVDataFooter.vue').default,CoverImage: require('/code/src/components/img/CoverImage.vue').default,AttachmentIcon: require('/code/src/components/icons/AttachmentIcon.vue').default,CustomizableHeaderTable: require('/code/src/components/table/CustomizableHeaderTable.vue').default})
