/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./TouchTextViewer.vue?vue&type=template&id=3b29834e"
import script from "./TouchTextViewer.vue?vue&type=script&lang=js"
export * from "./TouchTextViewer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgTextEditor: require('/code/src/components/svg/SvgTextEditor.vue').default,FrameText: require('/code/src/components/text/FrameText.vue').default,Input: require('/code/src/components/input/Input.vue').default,InsertionFrameText: require('/code/src/components/svg/InsertionFrameText.vue').default,SplitView: require('/code/src/components/SplitView.vue').default})
