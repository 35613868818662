var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('FloatingTooltip', {
    attrs: {
      "top": "",
      "max-width": "300px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticClass: "tw-inline-flex tw-bg-secondary tw-text-surface tw-rounded tw-px-2 tw-py-1 tw-items-center tw-justify-center tw-gap-1"
        }, on), [_c('mdi-information', {
          staticClass: "tw-size-4"
        }), _vm._v(" "), _c('span', {
          staticClass: "tw-text-caption tw-whitespace-nowrap"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('underSubmission')) + "\n        ")])], 1)];
      }
    }])
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('underSubmissionAnnotationForPrinting')) + "\n    ")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };