import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import Input from '@/components/input/Input.vue';
export default {
  extends: Input,
  props: {
    validText: {
      type: String,
      default: null
    }
  },
  computed: {
    decorationJson: function decorationJson() {
      var _this$value,
        _this = this;
      // Tiptapフォーマットで出力
      var text = (_this$value = this.value) !== null && _this$value !== void 0 ? _this$value : '';
      var overflow = this.validText ? text.replace(this.validText, '') : '';
      var validText = this.validText ? this.validText : text;
      return [{
        text: validText
      }].concat(_toConsumableArray(overflow.split(/\r\n|\n/).reduce(function (acc, text) {
        if (acc.length) acc.push({
          text: '<br />'
        });
        acc.push({
          text: text,
          marks: [{
            type: 'highlight',
            attrs: {
              color: "var(--v-error-lighten3, ".concat(_this.$vuetify.theme.currentTheme.error, ")")
            }
          }]
        });
        return acc;
      }, [])));
    }
  }
};