import { VDatePicker } from 'vuetify/lib';
import dayjs from 'dayjs';

// v-date-pickerの初期表示がrangeのlastDateなのでstartDateを表示するように改良
export default VDatePicker.extend({
  data: function data() {
    var _this = this;
    return {
      tableDate: function () {
        if (_this.pickerDate) {
          return _this.pickerDate;
        }
        return _this.genStartPageDate();
      }()
    };
  },
  methods: {
    genStartPageDate: function genStartPageDate() {
      var date = this.value ? Array.isArray(this.value) ? this.value[0] : this.value : typeof this.showCurrent === 'string' ? this.showCurrent : dayjs();
      return dayjs(date).format(this.type === 'date' ? 'YYYY-MM' : 'YYYY');
    },
    goPage: function goPage(date) {
      var _this$$formatDate;
      this.tableDate = (_this$$formatDate = this.$formatDate(date, 'YYYY-MM-DD')) !== null && _this$$formatDate !== void 0 ? _this$$formatDate : this.tableDate;
    },
    resetPage: function resetPage() {
      this.tableDate = this.genStartPageDate();
    }
  }
});