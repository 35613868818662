import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm$color;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tw-flex tw-flex-wrap tw-gap-6 tw-mb-4 tw-justify-between tw-w-full"
  }, [_c('span', {
    staticClass: "section-sub-title tw-flex tw-items-center tw-text-subtitle-1"
  }, [_vm.icon || _vm.number != null ? _c(VAvatar, {
    staticClass: "mr-2",
    attrs: {
      "size": "20",
      "color": (_vm$color = _vm.color) !== null && _vm$color !== void 0 ? _vm$color : 'primary-text'
    }
  }, [_c(VFadeTransition, {
    attrs: {
      "leave-absolute": ""
    }
  }, [_vm.icon ? _c(VIcon, {
    attrs: {
      "size": "20",
      "color": "color-surface"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.icon) + "\n        ")]) : _c('span', {
    staticClass: "color-surface--text"
  }, [_vm._v(_vm._s(_vm.number))])], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-grow-1 flex-wrap align-center",
    class: _vm.color != null ? "".concat(_vm.color, "--text") : null
  }, [_vm._t("default")], 2)], 1), _vm._v(" "), _vm._t("append")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };