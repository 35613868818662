/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ShippingDateSelect.vue?vue&type=template&id=085c6630"
import script from "./ShippingDateSelect.vue?vue&type=script&lang=ts&setup=true"
export * from "./ShippingDateSelect.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TwRadio: require('/code/src/components/input/TwRadio.vue').default,DateInput: require('/code/src/components/input/DateInput.vue').default})
