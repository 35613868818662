import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', {
    staticClass: "alert-area py-3 px-4 rounded d-flex align-center",
    class: _defineProperty({
      'my-4': !_setup.props.noMargin
    }, "alert-area--".concat(_setup.props.type), !!_setup.props.type)
  }, [_setup.icon ? _c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "color": _setup.props.type
    }
  }, [_vm._v("\n    " + _vm._s(_setup.icon) + "\n  ")]) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "text-body-2",
    class: {
      'mr-6': _vm.$slots.default
    }
  }, [_vm.$slots.message ? [_vm._t("message")] : [_vm._v("\n      " + _vm._s(_setup.props.message) + "\n    ")]], 2), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };