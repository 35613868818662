import { VCard } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var render = function render() {
  var _vm$range$start, _vm$range$end;
  var _vm = this,
    _c = _vm._self._c;
  return _c('FormHighlight', {
    staticClass: "input tw-flex-[1_1_auto]",
    attrs: {
      "value": _vm.value,
      "initial-value": _vm.initialValue
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$emit('input', $event);
      }
    }
  }, [_vm.label ? _c('SmallLabel', {
    staticClass: "pb-1",
    attrs: {
      "label": _vm.label,
      "required": _vm.required
    }
  }, [_vm._t("label.prepend")], 2) : _vm._e(), _vm._v(" "), _vm.type !== "range" ? _c(VMenu, {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "min-width": "290"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var _vm$attributes$hideDe, _vm$attributes$cleara;
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VTextField, _vm._g(_vm._b({
          ref: "input",
          attrs: {
            "slot": "activator",
            "value": _vm.$date(_vm.value),
            "placeholder": _vm.$date(new Date()),
            "readonly": "",
            "outlined": "",
            "prepend-inner-icon": "mdi-calendar",
            "hide-details": (_vm$attributes$hideDe = _vm.attributes.hideDetails) !== null && _vm$attributes$hideDe !== void 0 ? _vm$attributes$hideDe : !_vm.hasValidation,
            "rules": _vm.datePickerValidation,
            "clearable": (_vm$attributes$cleara = _vm.attributes.clearable) !== null && _vm$attributes$cleara !== void 0 ? _vm$attributes$cleara : !_vm.required
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', $event);
            },
            "click:clear": function clickClear($event) {
              return _vm.$emit('input', null);
            }
          },
          slot: "activator"
        }, 'v-text-field', _objectSpread(_objectSpread({}, attrs), _vm.attributes), false), on))];
      }
    }], null, false, 2216975847),
    model: {
      value: _vm.datepicker,
      callback: function callback($$v) {
        _vm.datepicker = $$v;
      },
      expression: "datepicker"
    }
  }, [_vm._v(" "), _c(VDatePicker, {
    ref: "picker",
    attrs: {
      "width": "290",
      "value": _vm.value || _vm.minDate,
      "no-title": "",
      "scrollable": "",
      "locale": "ja-JP",
      "day-format": function dayFormat(date) {
        return new Date(date).getDate();
      },
      "header-date-format": function headerDateFormat(date) {
        return /^\d{4}$/.test(date) ? date : _vm.$month(date);
      },
      "min": _vm.$iso8601(_vm.minDate),
      "max": _vm.$iso8601(_vm.maxDate)
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change', $event);
      },
      "input": function input($event) {
        _vm.$refs.menu.save($event);
        _vm.$refs.input.$emit('input', $event);
      }
    }
  })], 1) : _c(VMenu, {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "min-width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var _vm$$date, _vm$$date2, _vm$$date3, _vm$$date4, _vm$attributes$hideDe2, _vm$attributes$cleara2;
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c(VTextField, _vm._g(_vm._b({
          ref: "input",
          attrs: {
            "slot": "activator",
            "value": _vm.range.start || _vm.range.end ? "".concat((_vm$$date = _vm.$date(_vm.range.start)) !== null && _vm$$date !== void 0 ? _vm$$date : '', " \u301C ").concat((_vm$$date2 = _vm.$date(_vm.range.end)) !== null && _vm$$date2 !== void 0 ? _vm$$date2 : '') : null,
            "placeholder": "".concat((_vm$$date3 = _vm.$date(_vm.$today)) !== null && _vm$$date3 !== void 0 ? _vm$$date3 : '', " \u301C ").concat((_vm$$date4 = _vm.$date(_vm.$today)) !== null && _vm$$date4 !== void 0 ? _vm$$date4 : ''),
            "readonly": "",
            "outlined": "",
            "prepend-inner-icon": "mdi-calendar",
            "hide-details": (_vm$attributes$hideDe2 = _vm.attributes.hideDetails) !== null && _vm$attributes$hideDe2 !== void 0 ? _vm$attributes$hideDe2 : !_vm.hasValidation,
            "rules": _vm.validation,
            "clearable": (_vm$attributes$cleara2 = _vm.attributes.clearable) !== null && _vm$attributes$cleara2 !== void 0 ? _vm$attributes$cleara2 : !_vm.required
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', $event);
            },
            "click:clear": function clickClear($event) {
              return _vm.$emit('input', null);
            }
          },
          slot: "activator"
        }, 'v-text-field', _objectSpread(_objectSpread({}, attrs), _vm.attributes), false), on))];
      }
    }]),
    model: {
      value: _vm.datepicker,
      callback: function callback($$v) {
        _vm.datepicker = $$v;
      },
      expression: "datepicker"
    }
  }, [_vm._v(" "), _c(VCard, [_c('div', {
    staticClass: "d-flex align-center pa-4",
    staticStyle: {
      "width": "320px"
    }
  }, [_c(VTextField, {
    staticClass: "flex-equality",
    class: {
      selected: _vm.selected == 'start'
    },
    attrs: {
      "value": _vm.$date(_vm.range.start),
      "placeholder": _vm.$t('startDate'),
      "readonly": "",
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "focus": function focus($event) {
        _vm.selected = 'start';
        _vm.$refs.picker.goPage(_vm.range.start);
      },
      "input": function input($event) {
        _vm.range.start = $event;
      },
      "clear": function clear($event) {
        _vm.range.start = null;
      }
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "mx-2"
  }, [_vm._v("〜")]), _vm._v(" "), _c(VTextField, {
    staticClass: "flex-equality",
    class: {
      selected: _vm.selected == 'end'
    },
    attrs: {
      "value": _vm.$date(_vm.range.end),
      "placeholder": _vm.$t('endDate'),
      "readonly": "",
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "focus": function focus($event) {
        _vm.selected = 'end';
        _vm.$refs.picker.goPage(_vm.range.end);
      },
      "input": function input($event) {
        _vm.range.end = $event;
      },
      "clear": function clear($event) {
        _vm.range.end = null;
      }
    }
  })], 1), _vm._v(" "), _c('CustomDatePicker', {
    key: _vm.key,
    ref: "picker",
    class: _defineProperty({
      range: _vm.range
    }, "selected-".concat(_vm.selected), true),
    attrs: {
      "width": "320",
      "value":
      // endだけ指定したくても配列の1つ目が必ずstartとして解釈されるので、endだけの場合は[end, end]にする
      [(_vm$range$start = _vm.range.start) !== null && _vm$range$start !== void 0 ? _vm$range$start : _vm.range.end, (_vm$range$end = _vm.range.end) !== null && _vm$range$end !== void 0 ? _vm$range$end : _vm.range.start].filter(function (item) {
        return item != null;
      }),
      "no-title": "",
      "scrollable": "",
      "range": "",
      "locale": "ja-JP",
      "day-format": function dayFormat(date) {
        return new Date(date).getDate();
      },
      "header-date-format": function headerDateFormat(date) {
        return /^\d{4}$/.test(date) ? date : _vm.$month(date);
      },
      "min": _vm.$iso8601(_vm.minDate),
      "max": _vm.$iso8601(_vm.maxDate)
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change', $event);
      },
      "input": _vm.onInputRange
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };