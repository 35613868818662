var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.label ? _c('SmallLabel', {
    staticClass: "mb-1",
    attrs: {
      "label": _vm.label
    }
  }, [_vm._t("label.prepend")], 2) : _vm._e(), _vm._v(" "), _setup.value ? [_c('div', {
    ref: "divRef",
    staticClass: "tw-p-4 tw-bg-background tw-rounded-lg tw-text-body-1 tw-whitespace-pre-wrap"
  })] : _c('div', {
    staticClass: "tw-text-body-2 tw-text-disabled"
  }, [_vm._v("\n    " + _vm._s(_setup.$t('notSet')) + "\n  ")])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };