import { VCard } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, {
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "tw-aspect-[1/1] tw-w-full tw-max-h-[160px]"
  }, [_c('SkeletonLoader')], 1), _vm._v(" "), _c('div', {
    staticClass: "tw-text-body-2 tw-mx-3 tw-mt-2 tw-mb-0.5"
  }, [_c('SkeletonLoader', {
    attrs: {
      "text": "",
      "line": "2"
    }
  })], 1), _vm._v(" "), _vm.detail ? _c('div', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis",
      value: 2,
      expression: "2"
    }],
    staticClass: "tw-text-caption tw-mx-3 tw-mb-1"
  }, [_c('SkeletonLoader', {
    attrs: {
      "text": "caption",
      "width": "80"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-text-on-surface tw-text-subtitle-2 tw-mx-3 tw-mb-2"
  }, [_c('div', {
    staticClass: "tw-grow"
  }), _vm._v(" "), _c('SkeletonLoader', {
    attrs: {
      "text": "",
      "width": "40"
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };