import { VFadeTransition } from 'vuetify/lib/components/transitions';

var render = function render() {
  var _vm$attributes$hideDe;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input tw-relative tw-flex-[1_1_auto] [&_.v-counter]:!tw-text-on-surface",
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.focus = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.focus = false;
      }
    }
  }, [_vm.label ? _c('SmallLabel', {
    staticClass: "pb-1",
    attrs: {
      "label": _vm.label,
      "required": _vm.required
    }
  }, [_vm._t("label.prepend")], 2) : _vm._e(), _vm._v(" "), _c(VFadeTransition, {
    attrs: {
      "leave-absolute": ""
    }
  }, [_vm.focus && _vm.$listeners.contextmenu && !_vm.readonly ? _c('span', {
    staticClass: "p-absolute",
    staticStyle: {
      "top": "0",
      "right": "0"
    }
  }, [_c('IconButton', {
    attrs: {
      "color": "secondary-text",
      "icon": "mdi-dots-vertical"
    },
    on: {
      "click": _vm.onClickMenu
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('DecoratableTextArea', _vm._g(_vm._b({
    ref: "input",
    attrs: {
      "value": _vm.value,
      "json": _vm.decorationJson,
      "rows": "2",
      "max-rows": "10",
      "outlined": "",
      "hide-details": (_vm$attributes$hideDe = _vm.attributes.hideDetails) !== null && _vm$attributes$hideDe !== void 0 ? _vm$attributes$hideDe : !_vm.hasValidation,
      "rules": _vm.validation
    }
  }, 'DecoratableTextArea', _vm.attributes, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };