import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VSelect, {
    staticClass: "tw-p-0 small",
    attrs: {
      "value": _setup.props.type,
      "items": [{
        type: 'list',
        icon: 'mdi-view-list-outline'
      }, {
        type: 'grid',
        icon: 'mdi-view-grid-outline'
      }],
      "item-value": "type",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _setup.emits('update:type', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c(VIcon, [_vm._v(_vm._s(item.icon))])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c(VIcon, [_vm._v(_vm._s(item.icon))])];
      }
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };