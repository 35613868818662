import { VForm } from 'vuetify/lib/components/VForm';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.svg ? _c('SvgTextEditor', {
    ref: "svg",
    attrs: {
      "svg": _vm.svg,
      "shipping-target": _vm.previewTarget,
      "qr-url": _vm.qrUrl,
      "gift-url": _vm.giftUrl,
      "logo": _vm.logo,
      "required": _vm.required,
      "edit": _vm.edit,
      "rules": _vm.validation,
      "warning-rules": _vm.warningRules,
      "orderer": _vm.orderer,
      "use-default-text": _vm.useSvgDefaultText
    },
    on: {
      "update:error": _vm.onUpdateError,
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$emit('contextmenu', $event);
      }
    },
    model: {
      value: _vm.texts,
      callback: function callback($$v) {
        _vm.texts = $$v;
      },
      expression: "texts"
    }
  }) : _c('SplitView', {
    ref: "splitView",
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.mdAndDown
    },
    scopedSlots: _vm._u([_vm.edit || _vm.hasPlaceholder ? {
      key: "first",
      fn: function fn() {
        return _vm._l(_vm.customsTexts, function (c, index) {
          return _c('FrameText', {
            key: "".concat(c.custom_id),
            class: {
              'mt-4': index > 0
            },
            attrs: {
              "label": _vm.$t('of', [_vm.$t('preview'), c.label]),
              "value": _vm.getFormattedText(c.custom_id),
              "pre": ""
            }
          });
        });
      },
      proxy: true
    } : null, _vm.edit || !_vm.$vuetify.breakpoint.mdAndDown ? {
      key: "second",
      fn: function fn() {
        return [_vm.edit ? _c(VForm, {
          ref: "texts",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
            }
          }
        }, _vm._l(_vm.customsTexts, function (c, index) {
          return _c('Input', {
            key: "".concat(c.custom_id),
            class: {
              'mt-4': index > 0
            },
            attrs: {
              "label": c.label,
              "required": _vm.required,
              "type": "multiLine",
              "rules": _vm.validation,
              "warning-rules": _vm.warningRules
            },
            on: {
              "update:error": _vm.onUpdateError,
              "contextmenu": function contextmenu($event) {
                $event.preventDefault();
                return _vm.$emit('contextmenu', $event);
              }
            },
            model: {
              value: _vm.texts[c.custom_id],
              callback: function callback($$v) {
                _vm.$set(_vm.texts, c.custom_id, $$v);
              },
              expression: "texts[c.custom_id]"
            }
          });
        }), 1) : _vm._l(_vm.customsTexts, function (c, index) {
          var _vm$texts$c$custom_id, _vm$previewTarget, _vm$previewTarget2;
          return _c('InsertionFrameText', {
            key: "".concat(c.custom_id),
            class: {
              'mt-4': index > 0
            },
            attrs: {
              "label": c.label,
              "value": (_vm$texts$c$custom_id = _vm.texts[c.custom_id]) !== null && _vm$texts$c$custom_id !== void 0 ? _vm$texts$c$custom_id : '',
              "contact": (_vm$previewTarget = _vm.previewTarget) === null || _vm$previewTarget === void 0 ? void 0 : _vm$previewTarget.contact,
              "orderer": _vm.orderer,
              "address": (_vm$previewTarget2 = _vm.previewTarget) === null || _vm$previewTarget2 === void 0 ? void 0 : _vm$previewTarget2.shippingAddress
            }
          });
        })];
      },
      proxy: true
    } : null], null, true)
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };