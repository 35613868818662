import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "phone-frame",
    class: {
      tablet: _vm.tablet
    },
    style: "zoom: ".concat(Math.min(_setup.maxWidth, _vm.width) / _setup.maxWidth, "; max-width: ").concat(_setup.maxWidth, "px;")
  }, [_c('div', {
    staticClass: "content"
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };