import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('label', {
    staticClass: "tw-text-caption tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
  }, [_vm._v("\n  " + _vm._s(_vm.text) + "\n  "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.checked,
      expression: "checked"
    }],
    staticClass: "tw-peer tw-sr-only",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_setup.checked) ? _vm._i(_setup.checked, null) > -1 : _setup.checked
    },
    on: {
      "change": function change($event) {
        var $$a = _setup.checked,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_setup.checked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_setup.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _setup.checked = $$c;
        }
      }
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "tw-inline-block tw-w-[2em] tw-bg-disabled tw-rounded-full tw-p-[1px] after:tw-block after:tw-h-[1em] after:tw-w-[1em] after:tw-rounded-full after:tw-bg-white after:tw-transition peer-checked:tw-bg-primary peer-checked:after:tw-translate-x-[calc(100%-2px)]"
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };