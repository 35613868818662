import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { deepWatchHandler } from '@/util/watch-handler';
export default {
  model: {
    prop: 'value',
    event: 'update:value'
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: function _default() {
        return {
          sender: '',
          message: '',
          cta_text: '',
          cta_link: ''
        };
      }
    },
    logo: {
      type: String,
      default: ''
    },
    productTitle: {
      type: String,
      default: ''
    },
    productImage: {
      type: String,
      default: ''
    },
    shippingTargets: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    previewTargetIndex: {
      type: Number,
      default: 0
    },
    rules: {
      type: Array,
      default: null
    },
    checkPlaceholder: {
      type: Boolean,
      default: false
    },
    orderer: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      form: this.getInitialFormData(),
      previewWidth: 640,
      focus: null,
      replace: false,
      error: {
        sender: false,
        message: false,
        ctaText: false,
        ctaLink: false
      }
    };
  },
  computed: {
    previewTarget: function previewTarget() {
      var _this$shippingTargets;
      return (_this$shippingTargets = this.shippingTargets) === null || _this$shippingTargets === void 0 ? void 0 : _this$shippingTargets[this.previewTargetIndex];
    },
    hasPlaceholder: function hasPlaceholder() {
      var text = [this.form.sender, this.form.cta_link, this.form.cta_text].join('\n');
      return this.$checkIncludePlaceHolder(text);
    },
    validation: function validation() {
      var _this$rules;
      return [].concat(_toConsumableArray(this.checkPlaceholder ? [this.validCautionTextRule] : []), _toConsumableArray((_this$rules = this.rules) !== null && _this$rules !== void 0 ? _this$rules : []));
    },
    warningRules: function warningRules() {
      return this.checkPlaceholder ? [this.validEmptyPlaceholderRule] : [];
    },
    ctaTextValidation: function ctaTextValidation() {
      var _this = this;
      return [function (v) {
        return v.length <= 255 || _this.$t('error.overCharCount', [255]);
      }, function () {
        return !_this.form.cta_link || !!_this.form.cta_text || _this.$t('error.needCtaTextAndLink');
      }].concat(_toConsumableArray(this.validation));
    },
    ctaLinkValidation: function ctaLinkValidation() {
      var _this2 = this;
      return [function (v) {
        return _this2.$isValidUrlOrEmpty(v) || _this2.$t('error.url');
      }, function () {
        return !_this2.form.cta_text || !!_this2.form.cta_link || _this2.$t('error.needCtaTextAndLink');
      }].concat(_toConsumableArray(this.validation));
    },
    hasError: function hasError() {
      var _this3 = this;
      return Object.keys(this.error).some(function (key) {
        return _this3.error[key];
      });
    }
  },
  watch: {
    value: {
      handler: deepWatchHandler(function (val, oldVal) {
        this.form = this.getInitialFormData();
      }),
      deep: true
    },
    form: {
      handler: deepWatchHandler(function (val, oldVal) {
        this.$emit('update:value', val);
      }),
      deep: true
    },
    hasError: function hasError(val) {
      return this.$emit('update:error', val);
    },
    isOverflowButtonText: function isOverflowButtonText(val) {
      return this.$emit('update:error', !this.validate());
    },
    shippingTargets: {
      handler: deepWatchHandler(function () {
        var _filter$filter, _this$$refs, _this$$refs$sender, _this$$refs$sender$$r, _this$$refs2, _this$$refs2$message, _this$$refs2$message$;
        // 初期値に対するバリデーションの実行
        // Note: ギフト送付者名と受け取り手へのメッセージに対しては空の場合にリセットする
        this.validate();
        (_filter$filter = [(_this$$refs = this.$refs) === null || _this$$refs === void 0 ? void 0 : (_this$$refs$sender = _this$$refs.sender) === null || _this$$refs$sender === void 0 ? void 0 : (_this$$refs$sender$$r = _this$$refs$sender.$refs) === null || _this$$refs$sender$$r === void 0 ? void 0 : _this$$refs$sender$$r.input, (_this$$refs2 = this.$refs) === null || _this$$refs2 === void 0 ? void 0 : (_this$$refs2$message = _this$$refs2.message) === null || _this$$refs2$message === void 0 ? void 0 : (_this$$refs2$message$ = _this$$refs2$message.$refs) === null || _this$$refs2$message$ === void 0 ? void 0 : _this$$refs2$message$.input].filter(function (item) {
          return item != null;
        }).filter(function (input) {
          return !input.value;
        })) === null || _filter$filter === void 0 ? void 0 : _filter$filter.forEach(function (input) {
          return input.resetValidation();
        });
      }),
      deep: true
    }
  },
  mounted: function mounted() {
    var _this4 = this;
    this.$nextTick(function () {
      var _this4$$refs, _this4$$refs$sender, _this4$$refs2, _this4$$refs2$message, _this4$$refs3, _this4$$refs3$ctaText, _this4$$refs4, _this4$$refs4$ctaLink;
      _this4.$emit('update:error', !_this4.valid());
      _this4.error = {
        sender: ((_this4$$refs = _this4.$refs) === null || _this4$$refs === void 0 ? void 0 : (_this4$$refs$sender = _this4$$refs.sender) === null || _this4$$refs$sender === void 0 ? void 0 : _this4$$refs$sender.valid) === false,
        message: ((_this4$$refs2 = _this4.$refs) === null || _this4$$refs2 === void 0 ? void 0 : (_this4$$refs2$message = _this4$$refs2.message) === null || _this4$$refs2$message === void 0 ? void 0 : _this4$$refs2$message.valid) === false,
        ctaText: ((_this4$$refs3 = _this4.$refs) === null || _this4$$refs3 === void 0 ? void 0 : (_this4$$refs3$ctaText = _this4$$refs3.ctaText) === null || _this4$$refs3$ctaText === void 0 ? void 0 : _this4$$refs3$ctaText.valid) === false,
        ctaLink: ((_this4$$refs4 = _this4.$refs) === null || _this4$$refs4 === void 0 ? void 0 : (_this4$$refs4$ctaLink = _this4$$refs4.ctaLink) === null || _this4$$refs4$ctaLink === void 0 ? void 0 : _this4$$refs4$ctaLink.valid) === false
      };
    });
  },
  methods: {
    updateCtaError: function updateCtaError() {
      this.$refs.ctaLink.$refs.input.hasInput = true;
      this.$refs.ctaText.$refs.input.hasInput = true;
      this.error.ctaText = !this.$refs.ctaText.validate();
      this.error.ctaLink = !this.$refs.ctaLink.validate();
    },
    getInitialFormData: function getInitialFormData() {
      var _this$value$id, _this$value, _this$value$sender, _this$value2, _this$value$message, _this$value3, _this$value$cta_text, _this$value4, _this$value$cta_link, _this$value5;
      return {
        id: (_this$value$id = (_this$value = this.value) === null || _this$value === void 0 ? void 0 : _this$value.id) !== null && _this$value$id !== void 0 ? _this$value$id : undefined,
        sender: (_this$value$sender = (_this$value2 = this.value) === null || _this$value2 === void 0 ? void 0 : _this$value2.sender) !== null && _this$value$sender !== void 0 ? _this$value$sender : '',
        message: (_this$value$message = (_this$value3 = this.value) === null || _this$value3 === void 0 ? void 0 : _this$value3.message) !== null && _this$value$message !== void 0 ? _this$value$message : '',
        cta_text: (_this$value$cta_text = (_this$value4 = this.value) === null || _this$value4 === void 0 ? void 0 : _this$value4.cta_text) !== null && _this$value$cta_text !== void 0 ? _this$value$cta_text : '',
        cta_link: (_this$value$cta_link = (_this$value5 = this.value) === null || _this$value5 === void 0 ? void 0 : _this$value5.cta_link) !== null && _this$value$cta_link !== void 0 ? _this$value$cta_link : ''
      };
    },
    getFormattedText: function getFormattedText(text) {
      var _this$previewTarget, _this$previewTarget2, _this$previewTarget3;
      return ((_this$previewTarget = this.previewTarget) === null || _this$previewTarget === void 0 ? void 0 : _this$previewTarget.contact) != null ? this.$formatPlaceHolder((_this$previewTarget2 = this.previewTarget) === null || _this$previewTarget2 === void 0 ? void 0 : _this$previewTarget2.contact, text !== null && text !== void 0 ? text : '', this.orderer, (_this$previewTarget3 = this.previewTarget) === null || _this$previewTarget3 === void 0 ? void 0 : _this$previewTarget3.shippingAddress) : text !== null && text !== void 0 ? text : '';
    },
    valid: function valid() {
      var _this$$refs$form;
      var inputs = (_this$$refs$form = this.$refs.form) === null || _this$$refs$form === void 0 ? void 0 : _this$$refs$form.inputs;
      if (inputs != null) {
        return inputs.every(function (input) {
          return input.valid;
        });
      } else {
        return this.validFormValue();
      }
    },
    validate: function validate() {
      var form = this.$refs.form;
      if (form != null) {
        return form.validate() !== false;
      } else {
        return this.validFormValue();
      }
    },
    validFormValue: function validFormValue() {
      var _this5 = this;
      var isValidSender = this.validation.every(function (rule) {
        return rule(_this5.form.sender) === true;
      });
      var isValidMessage = this.validation.every(function (rule) {
        return rule(_this5.form.message) === true;
      });
      var isValidCtaText = this.ctaTextValidation.every(function (rule) {
        return rule(_this5.form.cta_text) === true;
      });
      var isValidCtaLink = this.ctaLinkValidation.every(function (rule) {
        return rule(_this5.form.cta_link) === true;
      });
      return isValidSender && isValidMessage && isValidCtaText && isValidCtaLink;
    },
    reset: function reset() {
      var _this$$refs$form2;
      (_this$$refs$form2 = this.$refs.form) === null || _this$$refs$form2 === void 0 ? void 0 : _this$$refs$form2.reset();
    },
    resetValidation: function resetValidation() {
      var _this$$refs$form3;
      (_this$$refs$form3 = this.$refs.form) === null || _this$$refs$form3 === void 0 ? void 0 : _this$$refs$form3.resetValidation();
    },
    validCautionTextRule: function validCautionTextRule(text) {
      var errorText = this.$getCautionText(text);
      if (errorText != null) {
        return errorText || this.$t('error.hasCautionPlaceholder');
      }
      return true;
    },
    validEmptyPlaceholderRule: function validEmptyPlaceholderRule(text) {
      var _this6 = this,
        _result$;
      var isMultiple = this.shippingTargets.length > 1;
      var result = this.shippingTargets.map(function (_ref) {
        var contact = _ref.contact,
          shippingAddress = _ref.shippingAddress;
        var placeholder = _this6.$getEmptyPlaceholders(contact, text, _this6.orderer, shippingAddress)[0];
        if (placeholder) {
          var isOrdererError = placeholder.startsWith('orderer_');
          var isAddressError = ['postcode', 'pref_name', 'city_name', 'street1', 'street2'].includes(placeholder);
          var prefix = '';
          if (isOrdererError) {
            // 例: オーダー作成者の
            prefix += _this6.$t('warning.hasEmptyPlaceHolder.prefix.orderer');
          } else if (isMultiple || !isAddressError) {
            // 例: "スマポ太郎"の
            prefix += _this6.$t('warning.hasEmptyPlaceHolder.prefix.contact', [_this6.$fullName(contact)]);
          }
          if (isAddressError) {
            if (!(shippingAddress !== null && shippingAddress !== void 0 && shippingAddress.label)) {
              // 配送先住所の
              prefix += _this6.$t('warning.hasEmptyPlaceHolder.prefix.emptyShippingAddress');
            } else {
              // 例: 配送先住所"自宅"の
              prefix += _this6.$t('warning.hasEmptyPlaceHolder.prefix.shippingAddress', [shippingAddress.label]);
            }
          }
          return prefix +
          // 例: "姓"が未設定のため {last_name} は空文字に置換されます
          _this6.$t('warning.hasEmptyPlaceHolder.main', [_this6.$langData().insertionPlaceholder[placeholder.replace(/^orderer_/, '')], "{{".concat(placeholder, "}}")]);
        } else {
          return null;
        }
      }).filter(function (item) {
        return item != null;
      });

      // 空文字で置換されるプレースホルダーの最初の１件を警告表示する
      return (_result$ = result[0]) !== null && _result$ !== void 0 ? _result$ : true;
    }
  }
};