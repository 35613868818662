import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/web.dom-collections.for-each.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import AutoResizeTextArea from '@/components/input/AutoResizeTextArea.vue';
export default AutoResizeTextArea.extend({
  props: _objectSpread(_objectSpread({}, AutoResizeTextArea.options.props), {}, {
    json: {
      type: Array,
      default: null
    }
  }),
  watch: {
    json: function json(newValue) {
      var _this = this;
      if (this.$refs.decorate instanceof HTMLDivElement) {
        this.$refs.decorate.innerHTML = this.genDecorationHtml(newValue);
        this.$nextTick(function () {
          _this.updateHeight();
          _this.updateScrollTop();
        });
      }
    },
    value: function value() {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.updateHeight();
        _this2.updateScrollTop();
      });
    }
  },
  mounted: function mounted() {
    this.copyStyle();
    if (this.$refs.decorate instanceof HTMLDivElement) {
      this.$refs.decorate.innerHTML = this.genDecorationHtml(this.json);
    }
    this.updateHeight();
    this.updateScrollTop();
  },
  methods: {
    genDecorationHtml: function genDecorationHtml(json) {
      return (json !== null && json !== void 0 ? json : []).map(function (node) {
        if (node.marks) {
          var span = document.createElement('span');
          span.innerText = node.text;
          if (!node.text) span.style.display = 'inline-block';
          node.marks.forEach(function (mark) {
            if (mark.type === 'highlight') {
              span.style.backgroundColor = mark.attrs.color;
              span.style.color = 'inherit';
            }
          });
          return span.outerHTML;
        } else {
          return node.text;
        }
      }).join('');
    },
    onInput: function onInput(e) {
      AutoResizeTextArea.options.methods.onInput.call(this, e);
      this.updateHeight();
      this.updateScrollTop();
    },
    genInput: function genInput() {
      var decorate = this.$createElement('div', {
        staticClass: 'decorate',
        ref: 'decorate'
      });
      var textarea = AutoResizeTextArea.options.methods.genInput.call(this);
      textarea.data.directives.push({
        name: 'resize-observer',
        value: this.updateHeight
      });
      textarea.data.on.scroll = this.updateScrollTop;
      return this.$createElement('div', {
        staticClass: 'wrapper'
      }, [decorate, textarea]);
    },
    copyStyle: function copyStyle() {
      if (this.$refs.decorate instanceof HTMLElement && this.$refs.input instanceof HTMLTextAreaElement) {
        var copyStyle = getComputedStyle(this.$refs.input);
        var cssText = copyStyle.cssText;
        if (cssText === '') {
          cssText = Object.values(copyStyle).reduce(function (acc, propertyName) {
            return "".concat(acc).concat(propertyName, ":").concat(copyStyle.getPropertyValue(propertyName), ";");
          }, '');
        }
        this.$refs.decorate.style.cssText = cssText;
      }
    },
    updateHeight: function updateHeight() {
      if (this.$refs.decorate instanceof HTMLElement && this.$refs.input instanceof HTMLTextAreaElement) {
        this.$refs.decorate.style.height = this.$refs.input.style.height || "".concat(this.$refs.input.clientHeight, "px");
      }
    },
    updateScrollTop: function updateScrollTop() {
      if (this.$refs.decorate instanceof HTMLElement && this.$refs.input instanceof HTMLTextAreaElement) {
        var _this$$refs$input;
        this.$refs.decorate.scrollTop = (_this$$refs$input = this.$refs.input) === null || _this$$refs$input === void 0 ? void 0 : _this$$refs$input.scrollTop;
      }
    }
  }
});