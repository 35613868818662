import { VDivider } from 'vuetify/lib/components/VDivider';

import "core-js/modules/es.string.small.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !!_vm.attachments.length ? _c('FloatingTooltip', {
    attrs: {
      "top": "",
      "hoverable": "",
      "content-class": "tw-bg-surface tw-text-on-surface tw-drop-shadow-[0_0_4px_rgb(var(--colors-border)/var(--tw-border-opacity))] tw-border-border"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.label ? _c('div', _vm._g({
          staticClass: "tw-inline-flex tw-w-fit tw-bg-surface tw-text-secondary tw-rounded-full tw-border tw-border-border tw-items-center tw-justify-center tw-cursor-default",
          class: {
            'tw-px-1 tw-py-0.5 tw-gap-0.5': _vm.small,
            'tw-px-2 tw-py-1 tw-gap-1': !_vm.small
          }
        }, on), [_c('mdi-paperclip', {
          class: {
            'tw-size-3': _vm.small,
            'tw-size-4': !_vm.small
          }
        }), _vm._v(" "), _c('span', {
          staticClass: "tw-text-caption tw-whitespace-nowrap"
        }, [_vm._v("\n        " + _vm._s(_setup.$t('printedMatter')) + "\n      ")])], 1) : _c('div', _vm._g({
          staticClass: "tw-inline-flex tw-w-fit tw-text-secondary tw-cursor-default"
        }, on), [_c('mdi-paperclip', {
          class: {
            'tw-size-3': _vm.small,
            'tw-size-4': !_vm.small
          }
        })], 1)];
      }
    }], null, false, 1574464109)
  }, [_vm._v(" "), _vm._l(_vm.attachments, function (attachment, i) {
    return [i != 0 ? _c(VDivider, {
      key: i
    }) : _vm._e(), _vm._v(" "), _c('div', {
      key: attachment.id,
      staticClass: "tw-flex tw-items-center tw-gap-1 tw-p-1 tw-click-effect",
      on: {
        "click": function click($event) {
          return _setup.preview(attachment);
        }
      }
    }, [_c('IconsExtPdf'), _vm._v(" "), _c('span', {
      staticClass: "tw-text-caption tw-text-secondary"
    }, [_vm._v("\n        " + _vm._s(attachment.file_name) + "\n      ")])], 1)];
  })], 2) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };