var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _vm.title,
      "actions": {},
      "width": "100%",
      "clazz": "tw-h-full tw-overscroll-y-none"
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click', $event);
      },
      "close": function close($event) {
        return _setup.emits('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "title.prepend",
      fn: function fn() {
        return [!_vm.isValid ? _c('UnderSubmissionIcon', {
          staticClass: "tw-mr-2"
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "tw-relative tw-w-full tw-overflow-y-hidden tw-h-full"
  }, [_setup.loading ? _c('Loading', {
    staticClass: "tw-absolute tw-inset-0"
  }) : _vm._e(), _vm._v(" "), _c('embed', {
    staticClass: "tw-size-full",
    attrs: {
      "src": _setup.props.url,
      "type": "application/pdf"
    },
    on: {
      "load": function load($event) {
        _setup.loading = false;
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };