/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0b8e3000"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,Input: require('/code/src/components/input/Input.vue').default,Loading: require('/code/src/components/Loading.vue').default,AlertArea: require('/code/src/components/AlertArea.vue').default,AddressAlert: require('/code/src/components/widgets/AddressAlert.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,AddressLabel: require('/code/src/components/widgets/AddressLabel.vue').default,OutlinedTable: require('/code/src/components/table/OutlinedTable.vue').default,IconButton: require('/code/src/components/button/IconButton.vue').default,ShippingDateSelect: require('/code/src/components/widgets/ShippingDateSelect.vue').default,TouchTextViewer: require('/code/src/components/widgets/TouchTextViewer.vue').default,GiftPageEditor: require('/code/src/components/widgets/GiftPageEditor.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default,TextEditorContextMenu: require('/code/src/components/menu/TextEditorContextMenu.vue').default})
