/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./GiftPageEditor.vue?vue&type=template&id=3bdfa73b"
import script from "./GiftPageEditor.vue?vue&type=script&lang=js"
export * from "./GiftPageEditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GiftPagePreview: require('/code/src/components/widgets/GiftPagePreview.vue').default,Input: require('/code/src/components/input/Input.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,InsertionFrameText: require('/code/src/components/svg/InsertionFrameText.vue').default,SmallLabel: require('/code/src/components/text/SmallLabel.vue').default,FrameText: require('/code/src/components/text/FrameText.vue').default,TwSwitch: require('/code/src/components/input/TwSwitch.vue').default,SplitView: require('/code/src/components/SplitView.vue').default})
