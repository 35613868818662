import type { WatchCallback } from 'vue'
import { cloneDeep, isEqual } from 'lodash-es'

// NOTE: Vueのwatchはobjectをミューテートさせてdeepオプションで監視した場合に、
// コールバックに渡されるnewVal/oldValが同じになる問題と、
// objectの入れ子になったプロパティの値が同じであっても、objectのインスタンスが違う場合に
// コールバックが実行される問題があるので、それを考慮したhandlerを生成する
// see https://v3.ja.vuejs.org/api/instance-methods.html
// ref https://github.com/vuejs/vue/issues/2164

export function deepWatchHandler(handler: WatchCallback): WatchCallback {
  return (() => {
    let snapshot: unknown
    return function (newVal, oldVal, onCleanup) {
      const value = cloneDeep(newVal ?? {})
      if (isEqual(oldVal, undefined)) snapshot = undefined
      if (!isEqual(value, snapshot ?? {})) {
        handler.call(this, value, snapshot, onCleanup)
        snapshot = value
      }
    }
  })()
}
