import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "skeleton-loader",
    class: {
      image: !_vm.text
    }
  }, _vm._l(parseInt(_vm.line), function (n) {
    return _c(VSkeletonLoader, _vm._g(_vm._b({
      key: "loader-".concat(n),
      class: _defineProperty({
        'loader-text': _vm.text
      }, _vm.typeClass, _vm.text)
    }, 'v-skeleton-loader', _vm.attributes, false), _vm.$listeners));
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };