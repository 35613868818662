import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _setup.$t('settings.messageTemplateTab'),
      "actions": {},
      "persistent": "",
      "loading": _setup.loading,
      "width": "800px"
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click', false);
      },
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_c(VCardText, {
    staticClass: "py-2"
  }, [_c('Input', {
    attrs: {
      "label": _setup.$t('keyword'),
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _setup.$t('placeholder.messageTemplate')
    },
    model: {
      value: _setup.keywordText,
      callback: function callback($$v) {
        _setup.keywordText = $$v;
      },
      expression: "keywordText"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "p-relative mt-4",
    staticStyle: {
      "min-height": "194px"
    }
  }, [_setup.templateTexts.length ? _c(VRow, _vm._l(_setup.templateTexts, function (item) {
    return _c(VCol, {
      key: item.id,
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c(VCard, {
      staticClass: "pa-4",
      attrs: {
        "elevation": "0",
        "outlined": ""
      }
    }, [_c('div', {
      staticClass: "d-flex mb-2"
    }, [_c('div', {
      staticClass: "text-subtitle-2"
    }, [_vm._v(_vm._s(item.name))]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('TextButton', {
      attrs: {
        "value": _setup.$t('action.copy')
      },
      on: {
        "click": function click($event) {
          return _setup.onClickCopy(item);
        }
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "color-background rounded pa-2"
    }, [_c('pre', {
      directives: [{
        name: "ellipsis",
        rawName: "v-ellipsis",
        value: 4,
        expression: "4"
      }],
      staticClass: "text-caption secondary-text--text",
      staticStyle: {
        "white-space": "pre-wrap"
      },
      domProps: {
        "textContent": _vm._s(item.template)
      }
    })])])], 1);
  }), 1) : _c('div', {
    staticClass: "pt-16 text-body-1 text-center"
  }, [_setup.keywordText ? [_vm._v("\n          " + _vm._s(_setup.$t('messageTemplate.filteredEmpty')) + "\n        ")] : [_vm._v("\n          " + _vm._s(_setup.$t('messageTemplate.empty')) + "\n        ")]], 2)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };