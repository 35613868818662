export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    detail: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected: function selected() {
      return this.active && this.selectable;
    }
  }
};