import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.isInvalidAddress ? _c('div', {
    staticClass: "text-body-2"
  }, [_setup.$isEmptyAddress(_setup.address) ? [_vm.atOrder ? [_vm._v("\n      " + _vm._s(_vm.$t('warning.addressInformationMissing1AtOrder')) + "\n    ")] : _vm.atCampaign ? [_vm._v("\n      " + _vm._s(_vm.$t('warning.addressInformationMissing1AtCampaign')) + "\n    ")] : [_vm._v("\n      " + _vm._s(_vm.$t('warning.addressInformationMissing1')) + "\n    ")]] : [_vm._v("\n    " + _vm._s(_vm.$t('warning.notInput', [_vm.$noInputAddressText(_setup.address)])) + "\n    "), _c('br'), _vm._v(" "), !_vm.fixedOrder && !_vm.atCampaign ? [_vm._v("\n      " + _vm._s(_vm.$t('warning.addressInformationMissing2')) + "\n    ")] : _vm._e()], _vm._v("\n  " + _vm._s(_vm.$t('warning.addressInformationMissing3')) + "\n  "), _c('TextButton', {
    staticStyle: {
      "vertical-align": "baseline"
    },
    attrs: {
      "value": _vm.$t('warning.addressInformationMissing4')
    },
    on: {
      "click": function click($event) {
        return _vm.$openAboutAddressConfirmation();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-help-circle")])];
      },
      proxy: true
    }], null, false, 2307809700)
  }), _vm._v("\n  " + _vm._s(_vm.$t('warning.addressInformationMissing5')) + "\n")], 2) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };