import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
var render = function render() {
  var _vm$$formatDate, _vm$$formatDate2, _setup$touches, _setup$touches2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c(_setup.ContextMenu, {
    ref: "menuRef"
  }, [_c('BetaIcon', {
    attrs: {
      "slot": "append.pin"
    },
    slot: "append.pin"
  })], 1), _vm._v(" "), _setup.selectable ? _c('div', {
    staticClass: "d-flex align-top mb-2"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_setup.filterOptions.keywordText ? _c(VChip, {
    staticClass: "mb-2 mr-2",
    attrs: {
      "outlined": "",
      "small": "",
      "close": "",
      "close-icon": "mdi-close"
    },
    on: {
      "click:close": function clickClose($event) {
        _setup.filterOptions.keywordText = '';
      }
    }
  }, [_vm._v("\n        " + _vm._s(_setup.$t('keyword') + ' : ' + _setup.filterOptions.keywordText) + "\n      ")]) : _vm._e(), _vm._v(" "), _setup.createdByNames.length ? _c(VChip, {
    staticClass: "mb-2 mr-2",
    attrs: {
      "outlined": "",
      "small": "",
      "close": "",
      "close-icon": "mdi-close"
    },
    on: {
      "click:close": function clickClose($event) {
        return _vm.$set(_setup.filterOptions, 'createdBy', []);
      }
    }
  }, [_vm._v("\n        " + _vm._s(_setup.$t('createBy') + ' : ' + _setup.createdByNames.join(', ')) + "\n      ")]) : _vm._e(), _vm._v(" "), _setup.filterOptions.createdAfterDate || _setup.filterOptions.createdBeforeDate ? _c(VChip, {
    staticClass: "mb-2 mr-2",
    attrs: {
      "outlined": "",
      "small": "",
      "close": "",
      "close-icon": "mdi-close"
    },
    on: {
      "click:close": function clickClose($event) {
        _setup.filterOptions.createdAfterDate = '';
        _setup.filterOptions.createdBeforeDate = '';
      }
    }
  }, [_vm._v("\n        " + _vm._s(_setup.$t('createAt') + ' : ' + ((_vm$$formatDate = _vm.$formatDate(_setup.filterOptions.createdAfterDate, 'YYYY/MM/DD')) !== null && _vm$$formatDate !== void 0 ? _vm$$formatDate : '') + '〜' + ((_vm$$formatDate2 = _vm.$formatDate(_setup.filterOptions.createdBeforeDate, 'YYYY/MM/DD')) !== null && _vm$$formatDate2 !== void 0 ? _vm$$formatDate2 : '')) + "\n      ")]) : _vm._e()], 1), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c(VMenu, {
    attrs: {
      "nudge-width": "200",
      "offset-y": true,
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs,
          visible = _ref.value;
        return [_c('button', _vm._g(_vm._b({
          staticClass: "tw-btn tw-btn-text-primary tw-mb-2 tw-whitespace-nowrap",
          attrs: {
            "type": "button"
          }
        }, 'button', attrs, false), on), [_c('mdi-tune', {
          staticClass: "tw-size-6"
        }), _vm._v("\n          " + _vm._s(_setup.$t('filter')) + "\n          "), visible ? _c('mdi-menu-up', {
          staticClass: "tw-size-6"
        }) : _c('mdi-menu-down', {
          staticClass: "tw-size-6"
        })], 1)];
      }
    }], null, false, 2137163470),
    model: {
      value: _setup.isShowFilterMenu,
      callback: function callback($$v) {
        _setup.isShowFilterMenu = $$v;
      },
      expression: "isShowFilterMenu"
    }
  }, [_vm._v(" "), _c(VCard, {
    staticClass: "pa-6"
  }, [_c('SectionSubTitle', {
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('button', {
          staticClass: "tw-btn tw-btn-text-primary",
          attrs: {
            "type": "button",
            "disabled": _setup.isEqual(_setup.tempFilterOptions, _setup.filterOptions)
          },
          domProps: {
            "textContent": _vm._s(_setup.$t('action.apply'))
          },
          on: {
            "click": function click($event) {
              _setup.filterOptions = _setup.cloneDeep(_setup.tempFilterOptions);
              _setup.isShowFilterMenu = false;
            }
          }
        })];
      },
      proxy: true
    }], null, false, 902879515)
  }, [_vm._v("\n          " + _vm._s(_setup.$t('filterSetting')) + "\n          ")]), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "label": _setup.$t('keyword'),
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _setup.$t('placeholder.keyword.touch')
    },
    model: {
      value: _setup.tempFilterOptions.keywordText,
      callback: function callback($$v) {
        _vm.$set(_setup.tempFilterOptions, "keywordText", $$v);
      },
      expression: "tempFilterOptions.keywordText"
    }
  }), _vm._v(" "), _setup.currentTeam ? _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "label": _setup.$t('createBy'),
      "items": _setup.currentTeam.users,
      "item-text": "name",
      "item-value": "email",
      "type": "autocomplete",
      "multiple": ""
    },
    model: {
      value: _setup.tempFilterOptions.createdBy,
      callback: function callback($$v) {
        _vm.$set(_setup.tempFilterOptions, "createdBy", $$v);
      },
      expression: "tempFilterOptions.createdBy"
    }
  }) : _vm._e(), _vm._v(" "), _c('DateInput', {
    staticClass: "mt-4",
    attrs: {
      "value": [_setup.tempFilterOptions.createdAfterDate, _setup.tempFilterOptions.createdBeforeDate],
      "type": "range",
      "max-date": _vm.$iso8601(_vm.$nextYear),
      "label": _setup.$t('createAt')
    },
    on: {
      "input": function input($event) {
        _setup.tempFilterOptions.createdAfterDate = $event === null || $event === void 0 ? void 0 : $event[0];
        _setup.tempFilterOptions.createdBeforeDate = $event === null || $event === void 0 ? void 0 : $event[1];
      }
    }
  })], 1)], 1)], 1) : _c(VRow, {
    staticClass: "mb-6"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('Input', {
    attrs: {
      "label": _setup.$t('keyword'),
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _setup.$t('placeholder.keyword.touch')
    },
    model: {
      value: _setup.filterOptions.keywordText,
      callback: function callback($$v) {
        _vm.$set(_setup.filterOptions, "keywordText", $$v);
      },
      expression: "filterOptions.keywordText"
    }
  })], 1), _vm._v(" "), _setup.currentTeam ? _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('Input', {
    attrs: {
      "label": _setup.$t('createBy'),
      "items": _setup.currentTeam.users,
      "item-text": "name",
      "item-value": "email",
      "type": "autocomplete",
      "multiple": ""
    },
    model: {
      value: _setup.filterOptions.createdBy,
      callback: function callback($$v) {
        _vm.$set(_setup.filterOptions, "createdBy", $$v);
      },
      expression: "filterOptions.createdBy"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('DateInput', {
    attrs: {
      "value": [_setup.filterOptions.createdAfterDate, _setup.filterOptions.createdBeforeDate],
      "type": "range",
      "max-date": _vm.$iso8601(_vm.$nextYear),
      "label": _setup.$t('createAt')
    },
    on: {
      "input": function input($event) {
        _setup.filterOptions.createdAfterDate = $event === null || $event === void 0 ? void 0 : $event[0];
        _setup.filterOptions.createdBeforeDate = $event === null || $event === void 0 ? void 0 : $event[1];
      }
    }
  })], 1), _vm._v(" "), !_setup.selectable ? _c(VCol, {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "12"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _setup.$t('touches.showOnlyAvailable'),
      "hide-details": ""
    },
    model: {
      value: _setup.filterOptions.onlyAvailable,
      callback: function callback($$v) {
        _vm.$set(_setup.filterOptions, "onlyAvailable", $$v);
      },
      expression: "filterOptions.onlyAvailable"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _setup.type === 'grid' ? _c('div', {
    staticClass: "p-relative"
  }, [_c(VProgressLinear, {
    attrs: {
      "absolute": "",
      "indeterminate": "",
      "active": _setup.loading
    }
  }), _vm._v(" "), _c(VDataIterator, {
    attrs: {
      "value": _setup.selectable ? [_setup.selectedItem] : undefined,
      "items": _setup.touches == null ? [1] : (_setup$touches = _setup.touches) !== null && _setup$touches !== void 0 ? _setup$touches : [],
      "item-key": "id",
      "options": _setup.tableOptions,
      "server-items-length": _setup.touches == null ? 1 : _setup.totalItems,
      "single-select": "",
      "hide-default-footer": ""
    },
    on: {
      "update:options": [function ($event) {
        _setup.tableOptions = $event;
      }, function ($event) {
        return _vm.$emit('update:options', $event);
      }],
      "input": function input($event) {
        return _setup.onSelectChanged($event === null || $event === void 0 ? void 0 : $event[0]);
      },
      "update:page": function updatePage($event) {
        _vm.$el.getBoundingClientRect().top < 0 && _vm.$el.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var items = _ref2.items,
          isSelected = _ref2.isSelected,
          select = _ref2.select;
        return [_setup.touches == null ? _c('div', {
          staticClass: "tw-flex tw-flex-wrap tw-justify-between -tw-m-2"
        }, [_vm._l(10, function (n) {
          return _c('div', {
            key: n,
            staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-m-2"
          }, [_c('ProductDummyCard', {
            attrs: {
              "detail": true
            }
          })], 1);
        }), _vm._v(" "), _vm._l(10, function (n) {
          return _c('div', {
            key: "spacer-".concat(n),
            staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-mx-2 tw-my-0 tw-h-0"
          });
        })], 2) : _c('div', {
          staticClass: "tw-flex tw-flex-wrap tw-justify-between -tw-m-2"
        }, [_vm._l(items, function (item) {
          var _item$shopify_product;
          return _c('div', {
            key: item.id,
            staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-m-2 tw-relative"
          }, [_c('ProductCard', {
            attrs: {
              "item": item,
              "detail": true,
              "active": _setup.selectable && isSelected(item),
              "selectable": _setup.selectable,
              "disabled": _setup.selectable && ((_item$shopify_product = item.shopify_product) === null || _item$shopify_product === void 0 ? void 0 : _item$shopify_product.product_status) !== 'ACTIVE'
            },
            on: {
              "click": function click(item, event) {
                if (_setup.selectable) select(isSelected(item) ? null : item);else _vm.$emit('click', item, event);
              },
              "contextmenu": function contextmenu($event) {
                $event.preventDefault();
                return _setup.onRightClick($event, item);
              }
            }
          }), _vm._v(" "), _setup.isTouchPinned(item) ? _c('IconButton', {
            staticStyle: {
              "position": "absolute",
              "top": "8px",
              "left": "8px"
            },
            on: {
              "click": function click($event) {
                return _setup.touchUnpinning(item, true);
              }
            }
          }, [_c('IconsPinCircle')], 1) : _vm._e()], 1);
        }), _vm._v(" "), _vm._l(10, function (n) {
          return _c('div', {
            key: "spacer-".concat(n),
            staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-mx-2 tw-my-0 tw-h-0"
          });
        })], 2)];
      }
    }, {
      key: "footer",
      fn: function fn(_ref3) {
        var pagination = _ref3.pagination,
          opt = _ref3.options,
          updateOptions = _ref3.updateOptions;
        return [_c('CustomVDataFooter', {
          attrs: {
            "pagination": pagination,
            "options": opt,
            "items-per-page-options": [10, 25, 50, 100]
          },
          on: {
            "update:options": updateOptions
          },
          scopedSlots: _vm._u([{
            key: "prepend",
            fn: function fn() {
              return [_c(VSpacer), _vm._v(" "), _c('TableTypeSelect', {
                staticClass: "tw-my-3 tw-mr-6 tw-flex-[0_0_0%]",
                attrs: {
                  "type": _setup.type
                },
                on: {
                  "update:type": function updateType($event) {
                    return _vm.$emit('update:type', $event);
                  }
                }
              })];
            },
            proxy: true
          }], null, true)
        })];
      }
    }], null, false, 4033868128)
  })], 1) : _c('CustomizableHeaderTable', {
    key: _setup.isEnabledPinning,
    attrs: {
      "value": _setup.selectable ? [_setup.selectedItem] : undefined,
      "headers": [{
        text: 'タッチ名',
        value: 'product_img',
        multiLine: true,
        ellipsis: false,
        hideable: false
      }, {
        text: '商品名',
        value: 'product_name',
        multiLine: true
      }, {
        text: '商品バリエーション',
        value: 'shopify_product.variant_title',
        multiLine: true,
        cellClass: 'text-caption'
      }, {
        text: '商品価格',
        value: 'shopify_product.variant_price',
        align: 'center',
        transform: _vm.$price
      }, {
        text: 'QR遷移先URL',
        value: 'url',
        multiLine: true,
        cellClass: 'text-caption',
        empty: _setup.$t('notSet')
      }, {
        text: '送付数',
        value: 'order_count',
        align: 'center',
        transform: function transform(val) {
          return _setup.$t('case', [val.toLocaleString()]);
        }
      }, {
        text: 'QR反響/クリック数',
        value: 'touched_count',
        align: 'center',
        getter: function getter(val) {
          var _val$touched_count, _val$lp_opened_count;
          return _setup.$t('case', [(_val$touched_count = val === null || val === void 0 ? void 0 : val.touched_count) !== null && _val$touched_count !== void 0 ? _val$touched_count : 0]) + '/' + _setup.$t('case', [(_val$lp_opened_count = val === null || val === void 0 ? void 0 : val.lp_opened_count) !== null && _val$lp_opened_count !== void 0 ? _val$lp_opened_count : 0]);
        }
      }, {
        text: 'タッチ作成日',
        value: 'created_at',
        transform: _vm.$date
      }, _setup.isEnabledPinning ? {
        text: '',
        value: 'pin',
        ellipsis: false,
        sortable: false,
        align: 'center',
        width: 1
      } : null].filter(function (item) {
        return item != null;
      }),
      "default-headers": ['タッチ名', '商品名', '商品価格', 'QR遷移先URL', '送付数'],
      "items": ((_setup$touches2 = _setup.touches) !== null && _setup$touches2 !== void 0 ? _setup$touches2 : []).map(function (x) {
        var _x$shopify_product;
        return _objectSpread(_objectSpread({}, x), {}, {
          isDisable: _setup.selectable && ((_x$shopify_product = x.shopify_product) === null || _x$shopify_product === void 0 ? void 0 : _x$shopify_product.product_status) !== 'ACTIVE'
        });
      }),
      "item-key": "id",
      "loading": _setup.loading || _setup.touches == null,
      "options": _setup.tableOptions,
      "server-items-length": _setup.totalItems,
      "disable-sort": true,
      "single-select": "",
      "checkbox-color": "primary",
      "show-select": _setup.selectable
    },
    on: {
      "update:options": [function ($event) {
        _setup.tableOptions = $event;
      }, function ($event) {
        return _vm.$emit('update:options', $event);
      }],
      "input": function input($event) {
        return _setup.onSelectChanged($event === null || $event === void 0 ? void 0 : $event[0]);
      },
      "click:row": function clickRow(item, row, event) {
        if (_setup.selectable) row.select(!row.isSelected);else _vm.$emit('click', item, event);
      },
      "auxclick:row": function auxclickRow(event, row) {
        return !_setup.selectable && _vm.$emit('click', row.item, event);
      },
      "contextmenu:row": function contextmenuRow(event, _ref4) {
        var item = _ref4.item;
        _setup.onRightClick(event, item);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.pin",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_setup.isTouchPinned(item) ? _c('IconButton', {
          attrs: {
            "icon": "mdi-pin",
            "color": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _setup.touchUnpinning(item, true);
            }
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.product_img",
      fn: function fn(_ref6) {
        var _item$shopify_product2, _item$shopify_product3;
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "tw-flex tw-items-center tw-min-w-[100px] tw-whitespace-normal"
        }, [_c('CoverImage', {
          staticClass: "!tw-size-[56px] tw-flex-shrink-0 tw-rounded tw-my-2 tw-mr-3",
          attrs: {
            "src": (_item$shopify_product2 = item.shopify_product) === null || _item$shopify_product2 === void 0 ? void 0 : (_item$shopify_product3 = _item$shopify_product2.images) === null || _item$shopify_product3 === void 0 ? void 0 : _item$shopify_product3[0]
          }
        }), _vm._v(" "), _c('div', [_c('div', {
          directives: [{
            name: "ellipsis",
            rawName: "v-ellipsis.flexible",
            value: 2,
            expression: "2",
            modifiers: {
              "flexible": true
            }
          }]
        }, [_vm._v(_vm._s(item.name))]), _vm._v(" "), _c('AttachmentIcon', {
          attrs: {
            "small": "",
            "label": "",
            "attachments": item.attachments
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.product_name",
      fn: function fn(_ref7) {
        var _item$shopify_product4, _item$shopify_product5;
        var item = _ref7.item;
        return [((_item$shopify_product4 = item.shopify_product) === null || _item$shopify_product4 === void 0 ? void 0 : _item$shopify_product4.product_status) === 'ACTIVE' ? _c('div', {
          staticClass: "text-caption"
        }, [_vm._v("\n        " + _vm._s((_item$shopify_product5 = item.shopify_product) === null || _item$shopify_product5 === void 0 ? void 0 : _item$shopify_product5.product_title) + "\n      ")]) : _c('div', {
          staticClass: "text-caption error--text"
        }, [_vm._v("\n        " + _vm._s(_setup.$t('error.notFound.product')) + "\n      ")])];
      }
    }, {
      key: "footer.prepend",
      fn: function fn() {
        return [_c(VSpacer), _vm._v(" "), _c('TableTypeSelect', {
          staticClass: "tw-my-3 tw-mr-6 tw-flex-[0_0_0%]",
          attrs: {
            "type": _setup.type
          },
          on: {
            "update:type": function updateType($event) {
              return _vm.$emit('update:type', $event);
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };