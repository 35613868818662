import { VIcon } from 'vuetify/lib/components/VIcon';

import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.string.small.js";
var render = function render() {
  var _setup$props$textClas, _setup$props$color, _setup$props$textClas2, _setup$props$color2, _setup$props$textClas3;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    style: {
      width: '100%',
      maxWidth: _setup.maxWidth
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_setup.icon != null ? _c('FloatingTooltip', {
    attrs: {
      "top": "",
      "hoverable": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var _setup$icon$color;
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VIcon, _vm._g(_vm._b({
          staticClass: "flex-shrink-0",
          class: {
            'mr-2': !_setup.props.small,
            'mr-1': _setup.props.small
          },
          attrs: {
            "size": _setup.props.small ? 16 : 24,
            "color": (_setup$icon$color = _setup.icon.color) !== null && _setup$icon$color !== void 0 ? _setup$icon$color : _vm.color
          }
        }, 'v-icon', attrs, false), on), [_vm._v("\n          " + _vm._s(_setup.icon.icon) + "\n        ")])];
      }
    }], null, false, 1109505790)
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-caption",
    staticStyle: {
      "white-space": "pre-line"
    },
    domProps: {
      "textContent": _vm._s(_setup.icon.tooltip)
    }
  }), _vm._v(" "), _setup.icon.helpClick ? _c('IconButton', {
    attrs: {
      "icon": "mdi-help-circle"
    },
    on: {
      "click": _setup.icon.helpClick
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('span', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis.flexible",
      value: 1,
      expression: "1",
      modifiers: {
        "flexible": true
      }
    }],
    staticClass: "flex-shrink-1",
    class: _defineProperty({
      'text-body-1': !_setup.props.small,
      'text-body-2': _setup.props.small
    }, (_setup$props$textClas = _setup.props.textClass) !== null && _setup$props$textClas !== void 0 ? _setup$props$textClas : '', true)
  }, [_vm._v("\n      " + _vm._s(_setup.props.address.label) + "\n    ")]), _vm._v(" "), _vm._t("label.prepend"), _vm._v(" "), _setup.props.detail && !_vm.rows ? _c('span', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis.flexible",
      value: 1,
      expression: "1",
      modifiers: {
        "flexible": true
      }
    }],
    key: "address",
    staticClass: "ml-2",
    class: _defineProperty(_defineProperty(_defineProperty({
      'text-body-2': !_setup.props.small,
      'text-caption': _setup.props.small
    }, "".concat((_setup$props$color = _setup.props.color) !== null && _setup$props$color !== void 0 ? _setup$props$color : 'secondary-text', "--text"), !_setup.props.selected), _setup.props.activeClass, _setup.props.selected), (_setup$props$textClas2 = _setup.props.textClass) !== null && _setup$props$textClas2 !== void 0 ? _setup$props$textClas2 : '', true),
    staticStyle: {
      "max-width": "300px"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$address(_setup.props.address)) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm.alert && !_vm.rows && !_vm.$isFillAddress(_vm.address) ? _c('FloatingTooltip', {
    key: "alert",
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var _setup$props$alertCol;
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c(VIcon, _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "size": _setup.props.small ? 12 : 16,
            "color": (_setup$props$alertCol = _setup.props.alertColor) !== null && _setup$props$alertCol !== void 0 ? _setup$props$alertCol : _setup.props.color
          }
        }, 'v-icon', attrs, false), on), [_vm._v("\n          mdi-alert-outline\n        ")])];
      }
    }], null, false, 3024320439)
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v("\n        " + _vm._s(_setup.$t('warning.notInput', [_vm.$noInputAddressText(_vm.address)])) + "\n      ")])]) : _vm._e()], 2), _vm._v(" "), _setup.props.detail && _vm.rows ? _c('div', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis",
      value: !!_setup.maxWidth ? 1 : false,
      expression: "!!maxWidth ? 1 : false"
    }],
    key: "address",
    class: _defineProperty(_defineProperty(_defineProperty({
      'mt-1': !_setup.props.small,
      'text-body-2': !_setup.props.small,
      'text-caption': _setup.props.small
    }, "".concat((_setup$props$color2 = _setup.props.color) !== null && _setup$props$color2 !== void 0 ? _setup$props$color2 : 'secondary-text', "--text"), !_setup.props.selected), _setup.props.activeClass, _setup.props.selected), (_setup$props$textClas3 = _setup.props.textClass) !== null && _setup$props$textClas3 !== void 0 ? _setup$props$textClas3 : '', true)
  }, [_vm._v("\n    " + _vm._s(_vm.$address(_setup.props.address)) + "\n\n    "), _vm.alert && !_vm.$isFillAddress(_vm.address) ? _c('FloatingTooltip', {
    key: "alert",
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var _setup$props$alertCol2;
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c(VIcon, _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "size": _setup.props.small ? 12 : 16,
            "color": (_setup$props$alertCol2 = _setup.props.alertColor) !== null && _setup$props$alertCol2 !== void 0 ? _setup$props$alertCol2 : _setup.props.color
          }
        }, 'v-icon', attrs, false), on), [_vm._v("\n          mdi-alert-outline\n        ")])];
      }
    }], null, false, 3024320439)
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v("\n        " + _vm._s(_setup.$t('warning.notInput', [_vm.$noInputAddressText(_vm.address)])) + "\n      ")])]) : _vm._e()], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };