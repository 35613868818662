import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('InnerDialog', _vm._b({
    on: {
      "open": _vm.open,
      "close": _vm.close
    },
    model: {
      value: _vm.display,
      callback: function callback($$v) {
        _vm.display = $$v;
      },
      expression: "display"
    }
  }, 'InnerDialog', _vm.attributes, false), [_vm.$slots.loading ? [_vm._t("loading")] : _c(VCard, [_c(VCardTitle, [_c('h5', [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _vm._t("title.append"), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info",
    attrs: {
      "type": "button",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.onClickClose
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  })], 1)], 2), _vm._v(" "), _c(VCardText, {
    ref: "content",
    class: {
      noBodyPadding: _vm.noBodyPadding
    }
  }, [_vm.$slots.default ? [_c(_vm.contentsTag, {
    ref: "form",
    tag: "component",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_vm._t("default")], 2)] : [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")]], 2), _vm._v(" "), _vm.$slots.action ? _c(VCardActions, [_vm._t("action")], 2) : _vm.negative || _vm.positive ? _c(VCardActions, {
    staticClass: "tw-gap-2"
  }, [_c(VSpacer), _vm._v(" "), _vm.negative ? _c('button', {
    staticClass: "tw-btn tw-btn-text-secondary !tw-text-body-1 !tw-p-2",
    attrs: {
      "type": "button",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.onClickNegative
    }
  }, [_vm._v("\n        " + _vm._s(_vm.negative) + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.positive ? _c('button', {
    staticClass: "tw-btn tw-btn-text-primary !tw-text-subtitle-1 !tw-p-2",
    attrs: {
      "type": "button",
      "disabled": _vm.disabledPositive || _vm.loading
    },
    on: {
      "click": _vm.onClickPositive
    }
  }, [_vm._v("\n        " + _vm._s(_vm.positive) + "\n      ")]) : _vm._e()], 1) : _vm._e()], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };