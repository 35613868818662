/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=44bfe14d"
import script from "./ProductCard.vue?vue&type=script&lang=js"
export * from "./ProductCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoverImage: require('/code/src/components/img/CoverImage.vue').default,TwCheckbox: require('/code/src/components/input/TwCheckbox.vue').default,AttachmentIcon: require('/code/src/components/icons/AttachmentIcon.vue').default})
