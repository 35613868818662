var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tw-flex tw-flex-col tw-gap-2 tw-mt-6"
  }, [_c('TwRadio', {
    attrs: {
      "current": _setup.method,
      "value": "fastest",
      "name": "date",
      "disabled": _setup.fastestDate == null
    },
    on: {
      "update:current": function updateCurrent($event) {
        _setup.method = $event;
      }
    }
  }, [_c('div', {
    staticClass: "tw-text-on-surface"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('shipping.fastestDesiredArrivalDate')) + "\n    ")]), _vm._v(" "), _c('span', {
    staticClass: "ml-8 tw-text-body-1",
    class: {
      'disabled-text--text': _setup.method !== 'fastest'
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$date(_setup.fastestDate)) + "\n    ")])]), _vm._v(" "), _c('TwRadio', {
    attrs: {
      "current": _setup.method,
      "value": "select",
      "name": "date",
      "disabled": _setup.fastestDate == null
    },
    on: {
      "update:current": function updateCurrent($event) {
        _setup.method = $event;
      }
    }
  }, [_c('div', {
    staticClass: "tw-flex-shrink-0 tw-text-on-surface"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('shipping.specifyDesiredArrivalDate')) + "\n    ")]), _vm._v(" "), _c('DateInput', {
    staticClass: "d-inline-flex flex-grow-0 ml-4 tw-text-body-1",
    attrs: {
      "clearable": false,
      "disabled": _setup.method !== 'select' || _setup.fastestDate == null,
      "min-date": _vm.$iso8601(_setup.fastestDate),
      "error-messages": _setup.errorShippingDate ? _setup.$t('error.needReselectDesiredArrivalDate') : null
    },
    on: {
      "change": function change($event) {
        // 最短発送日を指定している場合は、最短指定されたことにする
        _setup.emits('update:shippingMethod', $event === _setup.fastestDate ? 'fastest' : 'select');
        _setup.emits('update:shippingDate', $event);
      }
    },
    model: {
      value: _setup.date,
      callback: function callback($$v) {
        _setup.date = $$v;
      },
      expression: "date"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };