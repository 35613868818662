/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./UnderSubmissionIcon.vue?vue&type=template&id=4fd9be1f"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default})
