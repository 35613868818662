var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "d-inline-flex secondary-text rounded-sm px-1 py-0.5"
  }, [_c('span', {
    staticClass: "text-overline color-surface--text",
    staticStyle: {
      "transform-origin": "center"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.$t('beta')) + "\n  ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };