import "core-js/modules/es.array.concat.js";
import ContactSelectableTable from './ContactSelectableTable.vue';
export default {
  components: {
    ContactSelectableTable: ContactSelectableTable
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      selectedItem: []
    };
  },
  computed: {
    dialog: {
      get: function get() {
        return this.show;
      },
      set: function set(value) {
        this.$emit('update:show', value);
      }
    },
    isSubmitEnable: function isSubmitEnable() {
      return this.selectedItem.length > 0;
    }
  },
  watch: {
    dialog: {
      handler: function handler(newVal, oldVal) {
        if (!oldVal && newVal) {
          var _this$$refs$table;
          (_this$$refs$table = this.$refs.table) === null || _this$$refs$table === void 0 ? void 0 : _this$$refs$table.reset();
        } else if (oldVal && !newVal) {
          var dialog = document.querySelector('.v-dialog--active');
          if (dialog) dialog.scrollTop = 0;
        }
      }
    }
  },
  methods: {
    submit: function submit() {
      this.$emit('change', this.value.concat(this.selectedItem));
      this.dialog = false;
    },
    resetForm: function resetForm() {
      this.selectedItem = [];
    }
  }
};