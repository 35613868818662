var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('label', {
    staticClass: "tw-min-h-6 tw-w-fit tw-text-subtitle-2 !tw-flex tw-gap-1 tw-items-center tw-rounded-md hover:tw-bg-mix-amount-10 active:tw-bg-mix-amount-30",
    class: {
      'tw-bg-mix-primary': _setup.checked,
      'tw-bg-mix-secondary': !_setup.checked,
      'tw-cursor-pointer': !_vm.disabled,
      'tw-pointer-events-none tw-text-disabled': _vm.disabled
    }
  }, [_c('input', {
    staticClass: "tw-hidden",
    attrs: {
      "type": "checkbox",
      "checked": "",
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _setup.checked
    },
    on: {
      "input": function input($event) {
        _setup.checked = !_setup.checked;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "tw-relative"
  }, [_c(_vm.circle ? _setup.MdiCircle : _setup.MdiCheckboxBlank, {
    tag: "component",
    staticClass: "tw-size-6 tw-text-surface tw-absolute tw-z-0"
  }), _vm._v(" "), _c(_vm.circle ? _setup.MdiCircleOutline : _setup.MdiCheckboxBlankOutline, {
    tag: "component",
    staticClass: "tw-relative tw-z-1",
    class: {
      '!tw-text-disabled': _vm.disabled,
      'tw-size-6 tw-p-0.5 tw-text-primary': _vm.cushion,
      'tw-size-6 tw-text-secondary': !_vm.cushion
    }
  }), _vm._v(" "), _c(_vm.circle ? _setup.MdiCheckCircle : _setup.MdiCheckboxMarked, {
    tag: "component",
    staticClass: "tw-text-primary tw-absolute tw-z-2 tw-inset-0 tw-transition-opacity tw-opacity-0",
    class: {
      '!tw-text-disabled': _vm.disabled,
      'tw-opacity-100': _setup.checked,
      'tw-size-6 tw-p-0.5': _vm.cushion,
      'tw-size-6': !_vm.cushion
    }
  })], 1), _vm._v(" "), _vm.$slots.default ? [_vm._t("default")] : [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };