import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Input from './Input.vue';
import dayjs from 'dayjs';
export default {
  extends: Input,
  props: _objectSpread(_objectSpread({}, Input.props), {}, {
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    }
  }),
  data: function data() {
    var _this$value, _this$value2;
    return {
      datepicker: false,
      selected: this.type === 'range' ? 'start' : null,
      key: dayjs().toString(),
      range: {
        start: this.validDateOrNull((_this$value = this.value) === null || _this$value === void 0 ? void 0 : _this$value[0]),
        end: this.validDateOrNull((_this$value2 = this.value) === null || _this$value2 === void 0 ? void 0 : _this$value2[1])
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, Input.computed), {}, {
    datePickerValidation: function datePickerValidation() {
      var _this = this;
      return this.validation.map(function (rule) {
        return function (v) {
          // YYYY/MM/DDからYYYY-MM-DDに変換してバリデーションかける
          return rule === null || rule === void 0 ? void 0 : rule(_this.$reformatDate(v, 'YYYY/MM/DD', 'YYYY-MM-DD'));
        };
      });
    }
  }),
  watch: {
    datepicker: function datepicker(newValue) {
      if (this.type === 'range' && !newValue) {
        this.key = dayjs().toString();
        this.selected = 'start';
      }
    },
    value: function value(newValue) {
      if (this.type === 'range') {
        this.range.start = this.validDateOrNull(newValue === null || newValue === void 0 ? void 0 : newValue[0]);
        this.range.end = this.validDateOrNull(newValue === null || newValue === void 0 ? void 0 : newValue[1]);
      }
    },
    range: {
      handler: function handler(newValue) {
        this.$refs.input.$emit('input', [newValue.start, newValue.end]);
      },
      deep: true
    }
  },
  methods: _objectSpread(_objectSpread({}, Input.methods), {}, {
    onInputRange: function onInputRange(value) {
      var start = this.range.start;
      var end = this.range.end;
      if (this.selected === 'start') {
        var date = value === null || value === void 0 ? void 0 : value[0];
        if (end && dayjs(end).isBefore(dayjs(date))) {
          this.range.start = end;
          this.range.end = date;
          this.selected = 'end';
        } else {
          this.range.start = date;
        }
      } else {
        var _value$;
        var _date = (_value$ = value === null || value === void 0 ? void 0 : value[1]) !== null && _value$ !== void 0 ? _value$ : value === null || value === void 0 ? void 0 : value[0]; // startが未選択の場合は配列の1つ目をendとして処理する
        if (start && dayjs(start).isAfter(dayjs(_date))) {
          this.range.start = _date;
          this.range.end = start;
          this.selected = 'start';
        } else {
          this.range.end = _date;
        }
      }
    },
    validDateOrNull: function validDateOrNull(value) {
      return value && dayjs(value).isValid() ? value : null;
    }
  })
};