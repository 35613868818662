import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomizableHeaderTable', {
    attrs: {
      "value": _vm.value,
      "sort-desc": ['created_at'],
      "headers": [{
        text: '氏名',
        value: 'name',
        getter: _vm.$fullName,
        hideable: false
      }, {
        text: '敬称',
        value: 'title',
        empty: _vm.$t('notSet')
      }, {
        text: '会社名',
        value: 'account.name',
        getter: function getter(val) {
          var _val$account$name, _val$account;
          return (_val$account$name = (_val$account = val.account) === null || _val$account === void 0 ? void 0 : _val$account.name) !== null && _val$account$name !== void 0 ? _val$account$name : val.crm_company_name;
        },
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: 'Webサイト',
        value: 'account.site_url',
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: '部署',
        value: 'department',
        empty: _vm.$t('notSet')
      }, {
        text: '役職',
        value: 'job_title',
        empty: _vm.$t('notSet')
      }, {
        text: 'メールアドレス',
        value: 'email',
        empty: _vm.$t('notSet')
      }, {
        text: '電話番号',
        value: 'tel',
        empty: _vm.$t('notSet')
      }, {
        text: 'CRM',
        value: 'crm',
        empty: _vm.$t('notLink'),
        ellipsis: false
      }, {
        text: '住所',
        value: 'shipping_addresses',
        ellipsis: false,
        empty: _vm.$t('notReg')
      }, {
        text: '会社住所',
        value: 'account.shipping_addresses',
        ellipsis: false,
        empty: _vm.$t('notReg')
      }, {
        text: '最終送付日',
        value: 'last_sent',
        transform: _vm.$date,
        empty: _vm.$t('none')
      }, {
        text: '送付数',
        value: 'order_count',
        align: 'center',
        transform: function transform(val) {
          return _vm.$t('case', [val.toLocaleString()]);
        }
      },
      // TODO APIの対応が必要 { text: 'コスト', value: 'cost', align: 'center', transform: $price },
      {
        text: 'キャンペーン数',
        value: 'campaign_count',
        align: 'center',
        transform: function transform(val) {
          return _vm.$t('case', [val.toLocaleString()]);
        }
      }, {
        text: '送付可否',
        value: 'delivery_block',
        align: 'center'
      }, {
        text: '所有者(担当者)',
        value: 'owner.name',
        empty: _vm.$t('notSet')
      }, {
        text: 'コンタクト作成日',
        value: 'created_at',
        transform: _vm.$date
      }, {
        text: 'ノート',
        value: 'note',
        align: 'center'
      }, {
        text: 'タグ',
        value: 'tags',
        empty: _vm.$t('notSet')
      }],
      "default-headers": ['氏名', '会社名', '部署', '役職', '所有者(担当者)', 'コンタクト作成日', '最終送付日', '送付数', 'キャンペーン数', '送付可否', 'ノート'],
      "items": _vm.contacts,
      "item-key": "id",
      "show-select": "",
      "fixed-header": "",
      "loading": _vm.loading.contact,
      "options": _vm.tableOptions,
      "server-items-length": _vm.totalItems,
      "disable-sort": true,
      "dense": "",
      "select-disable-key": "delivery_block",
      "select-disable-message": _vm.$t('campaigns.send.deliveryBlock'),
      "restore-key": "campaign-contact-select"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "input": function input($event) {
        return _vm.$emit('change', $event);
      },
      "click:row": function clickRow(item, row) {
        row.select(!row.isSelected);
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c(VRow, {
          staticClass: "mb-6"
        }, [_c(VCol, {
          attrs: {
            "cols": "6",
            "lg": "3"
          }
        }, [_c('Input', {
          attrs: {
            "type": "autocomplete",
            "multiple": "",
            "clearable": false,
            "label": _vm.$t('tagName'),
            "items": _vm.tags,
            "placeholder": _vm.$t('placeholder.select')
          },
          scopedSlots: _vm._u([_vm.loading.tag ? {
            key: "append",
            fn: function fn() {
              return [_c(VProgressCircular, {
                attrs: {
                  "size": "24",
                  "color": "primary",
                  "indeterminate": ""
                }
              })];
            },
            proxy: true
          } : null, {
            key: "selection",
            fn: function fn(_ref) {
              var attrs = _ref.attrs,
                item = _ref.item,
                parent = _ref.parent,
                selected = _ref.selected;
              return [_c(VChip, _vm._b({
                attrs: {
                  "input-value": selected,
                  "outlined": "",
                  "small": "",
                  "close": "",
                  "close-icon": "mdi-close"
                },
                on: {
                  "click:close": function clickClose($event) {
                    return parent.selectItem(item);
                  }
                }
              }, 'v-chip', attrs, false), [_c('span', {
                directives: [{
                  name: "ellipsis",
                  rawName: "v-ellipsis"
                }],
                staticStyle: {
                  "max-width": "160px"
                }
              }, [_vm._v("\n                " + _vm._s(item) + "\n              ")])])];
            }
          }], null, true),
          model: {
            value: _vm.filterOptions.selectTag,
            callback: function callback($$v) {
              _vm.$set(_vm.filterOptions, "selectTag", $$v);
            },
            expression: "filterOptions.selectTag"
          }
        })], 1), _vm._v(" "), _c(VCol, {
          attrs: {
            "cols": "6",
            "lg": "3"
          }
        }, [_c('Input', {
          attrs: {
            "type": "autocomplete",
            "multiple": "",
            "label": _vm.$t('owner'),
            "items": _vm.currentTeam.users,
            "item-text": "name",
            "item-value": "email",
            "placeholder": _vm.$t('placeholder.select'),
            "clearable": ""
          },
          scopedSlots: _vm._u([_vm.loading.teamMember ? {
            key: "append",
            fn: function fn() {
              return [_c(VProgressCircular, {
                attrs: {
                  "size": "24",
                  "color": "primary",
                  "indeterminate": ""
                }
              })];
            },
            proxy: true
          } : null], null, true),
          model: {
            value: _vm.filterOptions.selectOwner,
            callback: function callback($$v) {
              _vm.$set(_vm.filterOptions, "selectOwner", $$v);
            },
            expression: "filterOptions.selectOwner"
          }
        })], 1), _vm._v(" "), _c(VCol, {
          attrs: {
            "cols": "6",
            "lg": "3"
          }
        }, [_c('Input', {
          attrs: {
            "label": _vm.$t('keyword'),
            "clearable": "",
            "prepend-inner-icon": "mdi-magnify",
            "placeholder": _vm.$t('placeholder.keyword.contacts')
          },
          model: {
            value: _vm.filterOptions.keywordText,
            callback: function callback($$v) {
              _vm.$set(_vm.filterOptions, "keywordText", $$v);
            },
            expression: "filterOptions.keywordText"
          }
        })], 1), _vm._v(" "), _c(VCol, {
          attrs: {
            "cols": "6",
            "lg": "3"
          }
        }, [_c('DateInput', {
          attrs: {
            "label": _vm.$t('campaigns.lastShippingBefore'),
            "clearable": "",
            "placeholder": _vm.$t('placeholder.lastShippingBefore')
          },
          scopedSlots: _vm._u([{
            key: "label.prepend",
            fn: function fn() {
              return [_c('FloatingTooltip', {
                attrs: {
                  "right": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref2) {
                    var on = _ref2.on;
                    return [_c(VIcon, _vm._g({
                      staticClass: "ml-1",
                      attrs: {
                        "small": ""
                      }
                    }, on), [_vm._v("mdi-information")])];
                  }
                }])
              }, [_vm._v(" "), _c('pre', {
                staticClass: "text-body-2"
              }, [_vm._v(_vm._s(_vm.$t('campaigns.lastShippingBeforeNote')))])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filterOptions.lastShippingBefore,
            callback: function callback($$v) {
              _vm.$set(_vm.filterOptions, "lastShippingBefore", $$v);
            },
            expression: "filterOptions.lastShippingBefore"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "header.data-table-select",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "footer.prepend",
      fn: function fn() {
        return [_vm.contacts.length ? _c('TextButton', {
          attrs: {
            "icon": _vm.isAllDisplayedSelected ? 'mdi-checkbox-multiple-marked' : 'mdi-checkbox-multiple-blank-outline',
            "value": _vm.isAllDisplayedSelected ? _vm.$t('unselectAllInPage', [_vm.tableOptions.page, _vm.selectableContacts.length]) : _vm.$t('selectAllInPage', [_vm.tableOptions.page, _vm.selectableContacts.length])
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.toggleDisplayedSelected.apply(null, arguments);
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.shipping_addresses",
      fn: function fn(_ref3) {
        var _item$shipping_addres;
        var item = _ref3.item;
        return [(_item$shipping_addres = item.shipping_addresses) !== null && _item$shipping_addres !== void 0 && _item$shipping_addres.length ? _c('FloatingTooltip', {
          attrs: {
            "top": "",
            "hoverable": "",
            "max-width": "400px",
            "open-delay": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var _ref5, _item$shipping_addres2, _item$shipping_addres3;
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('div', _vm._g(_vm._b({
                staticClass: "d-flex"
              }, 'div', attrs, false), on), [_c('AddressLabel', {
                staticStyle: {
                  "pointer-events": "none"
                },
                attrs: {
                  "small": "",
                  "detail": "",
                  "rows": "",
                  "max-width": "200",
                  "alert": false,
                  "address": (_ref5 = (_item$shipping_addres2 = item.shipping_addresses.find(function (it) {
                    var _item$primary_shippin;
                    return it.id === ((_item$primary_shippin = item.primary_shipping_address) === null || _item$primary_shippin === void 0 ? void 0 : _item$primary_shippin.id);
                  })) !== null && _item$shipping_addres2 !== void 0 ? _item$shipping_addres2 : item.shipping_addresses[0]) !== null && _ref5 !== void 0 ? _ref5 : {}
                },
                scopedSlots: _vm._u([((_item$shipping_addres3 = item.shipping_addresses) === null || _item$shipping_addres3 === void 0 ? void 0 : _item$shipping_addres3.length) > 1 ? {
                  key: "label.prepend",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "ml-1 text-body-2 secondary-text--text"
                    }, [_vm._v("\n                " + _vm._s('+' + _vm.$t('case', [item.shipping_addresses.length - 1])) + "\n              ")])];
                  },
                  proxy: true
                } : null], null, true)
              })], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _vm._l(item.shipping_addresses, function (address, index) {
          return _c('AddressLabel', {
            key: index,
            class: {
              'mt-1': index > 0
            },
            attrs: {
              "detail": "",
              "rows": "",
              "small": "",
              "address": address,
              "color": "white"
            }
          });
        })], 2) : _vm._e()];
      }
    }, {
      key: "item.account.shipping_addresses",
      fn: function fn(_ref6) {
        var _item$account, _item$account$shippin;
        var item = _ref6.item;
        return [(_item$account = item.account) !== null && _item$account !== void 0 && (_item$account$shippin = _item$account.shipping_addresses) !== null && _item$account$shippin !== void 0 && _item$account$shippin.length ? _c('FloatingTooltip', {
          attrs: {
            "top": "",
            "hoverable": "",
            "max-width": "400px",
            "open-delay": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var _ref8, _item$account$shippin2, _item$account$shippin3;
              var on = _ref7.on,
                attrs = _ref7.attrs;
              return [_c('div', _vm._g(_vm._b({
                staticClass: "d-flex"
              }, 'div', attrs, false), on), [_c('AddressLabel', {
                staticStyle: {
                  "pointer-events": "none"
                },
                attrs: {
                  "small": "",
                  "detail": "",
                  "rows": "",
                  "max-width": "200",
                  "alert": false,
                  "address": (_ref8 = (_item$account$shippin2 = item.account.shipping_addresses.find(function (it) {
                    var _item$primary_shippin2;
                    return it.id === ((_item$primary_shippin2 = item.primary_shipping_address) === null || _item$primary_shippin2 === void 0 ? void 0 : _item$primary_shippin2.id);
                  })) !== null && _item$account$shippin2 !== void 0 ? _item$account$shippin2 : item.account.shipping_addresses[0]) !== null && _ref8 !== void 0 ? _ref8 : {}
                },
                scopedSlots: _vm._u([((_item$account$shippin3 = item.account.shipping_addresses) === null || _item$account$shippin3 === void 0 ? void 0 : _item$account$shippin3.length) > 1 ? {
                  key: "label.prepend",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "ml-1 text-body-2 secondary-text--text"
                    }, [_vm._v("\n                " + _vm._s('+' + _vm.$t('case', [item.account.shipping_addresses.length - 1])) + "\n              ")])];
                  },
                  proxy: true
                } : null], null, true)
              })], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _vm._l(item.account.shipping_addresses, function (address, index) {
          return _c('AddressLabel', {
            key: index,
            class: {
              'mt-1': index > 0
            },
            attrs: {
              "detail": "",
              "rows": "",
              "small": "",
              "address": address,
              "color": "white"
            }
          });
        })], 2) : _vm._e()];
      }
    }, {
      key: "item.crm",
      fn: function fn(_ref9) {
        var _vm$$datetime;
        var item = _ref9.item;
        return [item !== null && item !== void 0 && item.crm_id ? [item !== null && item !== void 0 && item.get_crm_display ? _c('LinkText', {
          attrs: {
            "text": item === null || item === void 0 ? void 0 : item.get_crm_display,
            "to": item === null || item === void 0 ? void 0 : item.crm_url
          }
        }) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "text-caption secondary-text--text"
        }, [_vm._v("\n        " + _vm._s(_vm.$t('lastSyncAt', [(_vm$$datetime = _vm.$datetime(item.last_synced_at)) !== null && _vm$$datetime !== void 0 ? _vm$$datetime : _vm.$t('notSync')])) + "\n      ")])] : _vm._e()];
      }
    }, {
      key: "item.delivery_block",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("\n      " + _vm._s(item.delivery_block ? 'mdi-close' : 'mdi-circle-outline') + "\n    ")])];
      }
    }, {
      key: "item.note",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item !== null && item !== void 0 && item.description ? _c(VMenu, {
          attrs: {
            "open-on-hover": "",
            "offset-y": "",
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref12) {
              var on = _ref12.on,
                attrs = _ref12.attrs;
              return [_c(VIcon, _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-note-outline")])];
            }
          }], null, true)
        }, [_vm._v(" "), _c(VCard, [_c(VCardText, [_c('pre', {
          domProps: {
            "textContent": _vm._s(item === null || item === void 0 ? void 0 : item.description)
          }
        })])], 1)], 1) : _vm._e()];
      }
    }, {
      key: "item.tags",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return _vm._l(item.tags, function (tag) {
          return _c(VChip, {
            key: tag,
            staticClass: "tw-mr-2",
            attrs: {
              "outlined": "",
              "small": ""
            }
          }, [_c('span', {
            directives: [{
              name: "ellipsis",
              rawName: "v-ellipsis"
            }],
            staticClass: "tw-max-w-[160px]"
          }, [_vm._v("\n        " + _vm._s(tag) + "\n      ")])]);
        });
      }
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };