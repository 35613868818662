import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p-relative"
  }, [!_vm.edit && _vm.raw ? _c('div', {
    ref: "display",
    staticClass: "svg-wrapper"
  }) : _c('SplitView', {
    ref: "splitView",
    attrs: {
      "vertical": _vm.isExpanded || _vm.$vuetify.breakpoint.mdAndDown
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('div', {
          staticClass: "svg-template mx-auto",
          class: {
            sticky: !_vm.$vuetify.breakpoint.mdAndDown
          },
          on: {
            "mouseenter": function mouseenter($event) {
              _vm.focus = true;
            },
            "mouseleave": function mouseleave($event) {
              _vm.focus = false;
            }
          }
        }, [_c('div', {
          ref: "display",
          staticClass: "svg-wrapper",
          on: {
            "contextmenu": function contextmenu($event) {
              $event.preventDefault();
              return _vm.$refs.menu.show($event);
            }
          }
        }), _vm._v(" "), _c('ContextMenu', {
          ref: "menu",
          attrs: {
            "items": _vm.menus
          }
        }, [_c('BetaIcon', {
          staticClass: "ml-1",
          attrs: {
            "slot": "append.overflow"
          },
          slot: "append.overflow"
        }), _vm._v(" "), _c('BetaIcon', {
          staticClass: "ml-1",
          attrs: {
            "slot": "append.counter"
          },
          slot: "append.counter"
        }), _vm._v(" "), _c('BetaIcon', {
          staticClass: "ml-1",
          attrs: {
            "slot": "append.patchPhoneNumber"
          },
          slot: "append.patchPhoneNumber"
        })], 1), _vm._v(" "), _c(VFadeTransition, {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.focus ? _c('span', {
          staticClass: "p-absolute",
          staticStyle: {
            "top": "0",
            "right": "0"
          }
        }, [_c('IconButton', {
          attrs: {
            "color": "secondary-text",
            "icon": "mdi-dots-vertical"
          },
          on: {
            "click": _vm.onClickMenu
          }
        })], 1) : _vm._e()])], 1)];
      },
      proxy: true
    }, _vm.edit || !_vm.$vuetify.breakpoint.mdAndDown ? {
      key: "second",
      fn: function fn() {
        return [_vm.edit ? _c(VForm, {
          ref: "form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
            }
          }
        }, [_vm._l(_vm.textNodeList, function (node, i) {
          return [_c('SvgTextInput', {
            key: node.id,
            ref: "input_".concat(node.id),
            refInFor: true,
            class: {
              'mt-2': i > 0
            },
            attrs: {
              "value": _vm.texts[node.id],
              "valid-text": _vm.debugShowOverflow ? _vm.displayedRowTextMap[node.id] : null,
              "readonly": node.hasAttribute('readonly'),
              "label": node.getAttribute('label'),
              "placeholder": node.getAttribute('placeholder'),
              "required": _vm.required,
              "disabled": _vm.processing[node.id],
              "rules": _vm.getValidationRules(node.id),
              "warning-rules": _vm.warningRules,
              "counter": _vm.debugEnableCounter,
              "counter-value": function counterValue() {
                if (_vm.overflowTextMap[node.id]) {
                  return _vm.$t('overflowChar', [_toConsumableArray(_vm.overflowTextMap[node.id]).length]);
                } else {
                  return _vm.$t('remainingFullWidthChar', [_vm.textRemainingLengthMap[node.id]]);
                }
              }
            },
            on: {
              "input": function input($event) {
                return _vm.textChange(node, $event);
              },
              "focus": function focus($event) {
                return _vm.setFocusOutline(node.id);
              },
              "blur": function blur($event) {
                return _vm.removeFocusOutline(node.id);
              },
              "contextmenu": function contextmenu($event) {
                $event.preventDefault();
                !node.hasAttribute('readonly') && _vm.$emit('contextmenu', $event);
              }
            },
            scopedSlots: _vm._u([{
              key: "label.prepend",
              fn: function fn() {
                var _vm$texts$node$id;
                return [_vm.$isValidUrl(node.getAttribute('help')) ? _c('IconButton', {
                  staticClass: "ml-1",
                  attrs: {
                    "icon": "mdi-help-circle"
                  },
                  on: {
                    "click": function click($event) {
                      _vm.$open(node.getAttribute('help'));
                    }
                  }
                }) : node.getAttribute('help') ? _c('FloatingTooltip', {
                  attrs: {
                    "top": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "activator",
                    fn: function fn(_ref) {
                      var on = _ref.on;
                      return [_c(VIcon, _vm._g({
                        staticClass: "ml-1",
                        attrs: {
                          "small": ""
                        }
                      }, on), [_vm._v("\n                    mdi-help-circle\n                  ")])];
                    }
                  }], null, true)
                }, [_vm._v(" "), _c('pre', {
                  staticClass: "text-body-2"
                }, [_vm._v(_vm._s(node.getAttribute('help')))])]) : _vm._e(), _vm._v(" "), _c(VSpacer), _vm._v(" "), !node.hasAttribute('readonly') && node.hasAttribute('ai') ? _c('TextButton', {
                  staticClass: "ml-2 mr-8",
                  attrs: {
                    "small": "",
                    "value": _vm.$t('action.textCorrectionAI'),
                    "disabled": ((_vm$texts$node$id = _vm.texts[node.id]) === null || _vm$texts$node$id === void 0 ? void 0 : _vm$texts$node$id.length) < 10,
                    "loading": _vm.processing[node.id],
                    "icon": "mdi-auto-fix"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.onClickTextCorrection(node);
                    }
                  }
                }, [_c('BetaIcon', {
                  staticClass: "ml-1",
                  attrs: {
                    "slot": "append"
                  },
                  slot: "append"
                })], 1) : _vm._e()];
              },
              proxy: true
            }], null, true)
          })];
        })], 2) : [_vm._l(_vm.textNodeList, function (node, i) {
          var _vm$shippingTarget, _vm$shippingTarget2;
          return _c('InsertionFrameText', {
            key: node.id,
            class: {
              'mt-2': i > 0
            },
            attrs: {
              "value": _vm.texts[node.id],
              "label": node.getAttribute('label'),
              "contact": (_vm$shippingTarget = _vm.shippingTarget) === null || _vm$shippingTarget === void 0 ? void 0 : _vm$shippingTarget.contact,
              "orderer": _vm.orderer,
              "address": (_vm$shippingTarget2 = _vm.shippingTarget) === null || _vm$shippingTarget2 === void 0 ? void 0 : _vm$shippingTarget2.shippingAddress,
              "replace": _vm.replace
            }
          });
        }), _vm._v(" "), _vm.hasPlaceholder ? _c('div', {
          staticClass: "tw-flex tw-justify-end tw-mt-4"
        }, [_c('TwSwitch', {
          attrs: {
            "value": _vm.replace,
            "text": _vm.$t('action.showReplacedPlaceholerText')
          },
          on: {
            "update:value": function updateValue($event) {
              _vm.replace = $event;
            }
          }
        })], 1) : _vm._e()]];
      },
      proxy: true
    } : null], null, true)
  }), _vm._v(" "), _c('div', {
    ref: "svg",
    attrs: {
      "id": "svg-temporary"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.svg)
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };