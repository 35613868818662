import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.ContextMenu, {
    ref: "menuRef",
    attrs: {
      "items": _setup.menuItems,
      "icon-color": "secondary-text"
    },
    scopedSlots: _vm._u([{
      key: "help.placeholder",
      fn: function fn() {
        return [_vm._v("\n    " + _vm._s(_vm.$t('aboutPlaceholder')) + "\n    "), _c('IconButton', {
          attrs: {
            "icon": "mdi-help-circle"
          },
          on: {
            "click": _vm.$openAboutPlaceholder
          }
        })];
      },
      proxy: true
    }, _setup.loading ? {
      key: "append.messageTemplate",
      fn: function fn() {
        return [_c(VProgressCircular, {
          attrs: {
            "size": "16",
            "width": "2",
            "color": "primary",
            "indeterminate": ""
          }
        })];
      },
      proxy: true
    } : null], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };