var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.label ? _c('SmallLabel', {
    staticClass: "mb-1",
    attrs: {
      "label": _vm.label
    }
  }, [_vm._t("label.prepend")], 2) : _vm._e(), _vm._v(" "), _vm.$slots.default ? [_c('div', {
    staticClass: "frame pa-4 color-background rounded"
  }, [_vm._t("default")], 2)] : _vm.value ? [_vm.pre ? _c('pre', {
    staticClass: "frame pa-4 color-background rounded",
    class: _setup.clazz,
    staticStyle: {
      "white-space": "pre-wrap"
    },
    style: _setup.style,
    domProps: {
      "textContent": _vm._s(_vm.value)
    }
  }) : _c('div', {
    staticClass: "frame pa-4 color-background rounded",
    class: _setup.clazz,
    style: _setup.style,
    domProps: {
      "textContent": _vm._s(_vm.value)
    }
  })] : _c('div', {
    staticClass: "text-body-2 disabled-text--text"
  }, [_vm._v("\n    " + _vm._s(_vm.$t('notSet')) + "\n  ")])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };