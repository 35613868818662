/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./SvgTextEditor.vue?vue&type=template&id=66983202&scoped=true"
import script from "./SvgTextEditor.vue?vue&type=script&lang=js"
export * from "./SvgTextEditor.vue?vue&type=script&lang=js"
import style0 from "./SvgTextEditor.vue?vue&type=style&index=0&id=66983202&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66983202",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BetaIcon: require('/code/src/components/icons/BetaIcon.vue').default,ContextMenu: require('/code/src/components/menu/ContextMenu.vue').default,IconButton: require('/code/src/components/button/IconButton.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,SvgTextInput: require('/code/src/components/svg/SvgTextInput.vue').default,InsertionFrameText: require('/code/src/components/svg/InsertionFrameText.vue').default,TwSwitch: require('/code/src/components/input/TwSwitch.vue').default,SplitView: require('/code/src/components/SplitView.vue').default})
