var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomDialog', {
    attrs: {
      "max-width": "1600px",
      "title": _vm.$t('action.selectionDestination'),
      "negative": _vm.$t('action.cancel'),
      "positive": _vm.$t('ofInSelection', [_vm.selectedItem.length]) + _vm.$t('action.doAdd'),
      "disabled-positive": !_vm.isSubmitEnable
    },
    on: {
      "click:positive": _vm.submit,
      "close": _vm.resetForm
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('ContactSelectableTable', {
    ref: "table",
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "exclude-items": _vm.value
    },
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };