import { VCard } from 'vuetify/lib/components/VCard';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm$item$shopify_prod, _vm$item, _vm$item$shopify_prod2, _vm$item$shopify_prod3, _vm$item2, _vm$item2$images, _vm$item3, _vm$item4, _vm$item$shopify_prod4, _vm$item$shopify_prod5, _vm$item5, _vm$item5$shopify_pro, _vm$item6, _vm$item6$shopify_pro;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, {
    staticClass: "selectable",
    class: {
      selected: _vm.selected,
      selectable: _vm.selectable
    },
    attrs: {
      "elevation": "0",
      "outlined": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": function click(event) {
        _vm.$emit('click', _vm.item, event);
      },
      "auxclick": function auxclick(event) {
        _vm.$emit('click', _vm.item, event);
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$emit("contextmenu", $event);
      }
    }
  }, [_c('CoverImage', {
    attrs: {
      "src": (_vm$item$shopify_prod = (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : (_vm$item$shopify_prod2 = _vm$item.shopify_product) === null || _vm$item$shopify_prod2 === void 0 ? void 0 : (_vm$item$shopify_prod3 = _vm$item$shopify_prod2.images) === null || _vm$item$shopify_prod3 === void 0 ? void 0 : _vm$item$shopify_prod3[0]) !== null && _vm$item$shopify_prod !== void 0 ? _vm$item$shopify_prod : (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : (_vm$item2$images = _vm$item2.images) === null || _vm$item2$images === void 0 ? void 0 : _vm$item2$images[0]
    }
  }), _vm._v(" "), _vm.selectable ? _c('TwCheckbox', {
    staticClass: "tw-absolute tw-top-2 tw-right-2 tw-pointer-events-none",
    attrs: {
      "value": _vm.selected,
      "circle": "",
      "cushion": ""
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis",
      value: 2,
      expression: "2"
    }],
    staticClass: "tw-text-on-surface tw-text-body-2 tw-mx-3 tw-mt-2 tw-mb-0.5"
  }, [_vm._v("\n    " + _vm._s(_vm.detail ? (_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.name : (_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.product_title) + "\n  ")]), _vm._v(" "), _vm.detail ? _c('div', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis",
      value: 2,
      expression: "2"
    }],
    staticClass: "tw-text-secondary tw-text-caption tw-mx-3 tw-mb-1",
    class: {
      'error--text': ((_vm$item$shopify_prod4 = _vm.item.shopify_product) === null || _vm$item$shopify_prod4 === void 0 ? void 0 : _vm$item$shopify_prod4.product_status) !== 'ACTIVE'
    }
  }, [_vm._v("\n    " + _vm._s(((_vm$item$shopify_prod5 = _vm.item.shopify_product) === null || _vm$item$shopify_prod5 === void 0 ? void 0 : _vm$item$shopify_prod5.product_status) === 'ACTIVE' ? (_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : (_vm$item5$shopify_pro = _vm$item5.shopify_product) === null || _vm$item5$shopify_pro === void 0 ? void 0 : _vm$item5$shopify_pro.product_title : _vm.$t('error.notFound.product')) + "\n  ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-items-center tw-text-on-surface tw-text-body-2 tw-mx-3 tw-mb-2"
  }, [_vm.detail && _vm.item.attachments ? _c('AttachmentIcon', {
    attrs: {
      "small": "",
      "attachments": _vm.item.attachments
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "tw-grow"
  }), _vm._v("\n    " + _vm._s(_vm.$price(_vm.detail ? (_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : (_vm$item6$shopify_pro = _vm$item6.shopify_product) === null || _vm$item6$shopify_pro === void 0 ? void 0 : _vm$item6$shopify_pro.variant_price : _vm.item.product_price)) + "\n  ")], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };