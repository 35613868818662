import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    logo: {
      type: String,
      default: null
    },
    productImage: {
      type: String,
      default: ''
    },
    productTitle: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    ctaText: {
      type: String,
      default: ''
    },
    ctaLink: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 640
    },
    focus: {
      type: String,
      default: null,
      validator: function validator(value) {
        return ['sender', 'message', 'button'].includes(value);
      }
    },
    error: {
      type: Object,
      default: function _default() {
        return {
          sender: false,
          message: false,
          button: false
        };
      }
    }
  }
};